import { ErrorMessage, Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  addCountries,
  deleteCountries,
  fetchCountries,
} from "../../utils/apis";
import CountriesListTable from "./CountriesListTable";
//

const countriesInitialValues = {
  countryCode: "",
};

const Countries = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(true);
  const [countries, setCountries] = useState([]);
  const [dropdownCountries, setDropdownCountries] = useState([]);

  useEffect(() => {
    if (fetch) {
      (async function () {
        const result = await fetchCountries(enqueueSnackbar);
        if (result.statusCode === 200) {
          const filteredCountries = result.data.countries?.filter(
            (country) => country.status === true
          );
          const filteredCountriesDropdown = result.data.countries?.filter(
            (country) => country.status === false
          );
          setCountries(filteredCountries);
          setDropdownCountries(filteredCountriesDropdown);
        }
      })();
    }
    return setFetch(false);
  }, [fetch, setCountries, enqueueSnackbar]);

  const handleCountryDelete = async (id) => {
    try {
      const result = await deleteCountries(id, enqueueSnackbar);
      if (result?.status) {
        const index = countries.findIndex((country) => country._id === id);
        if (index !== -1) {
          const updatedCountries = countries.splice(index, 1)[0];
          setDropdownCountries([updatedCountries, ...dropdownCountries]);
          setCountries([...countries]);
        }
      }
    } catch (error) {
      error &&
        enqueueSnackbar(`${error?.message}`, {
          variant: "error",
          autoHideDuration: 3000,
        });
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // Handle form submission here
    try {
      const result = await addCountries(values, enqueueSnackbar);
      if (result?.status) {
        const index = dropdownCountries.findIndex(
          (country) => country.countryCode === values.countryCode
        );

        if (index !== -1) {
          const updatedCountries = dropdownCountries.splice(index, 1)[0];
          setDropdownCountries([...dropdownCountries]);
          setCountries([...countries, updatedCountries]);
        }
      }
      resetForm();
      setSubmitting(false);
    } catch (error) {
      error &&
        enqueueSnackbar(`${error?.message}`, {
          variant: "error",
          autoHideDuration: 3000,
        });
    }
  };

  const validationSchema = Yup.object().shape({
    countryCode: Yup.string().required("Country is required"),
  });

  return (
    <>
      <div
        className="col-lg-10 col-xl-8 col-xxl-6"
        style={{
          borderRadius: "8px",
          border: "1px solid #C8C8C8",
          padding: "15px",
          backgroundColor: "#fff",
        }}
      >
        <Formik
          initialValues={countriesInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <div className="row m-0 w-100 row-gap-2">
                <label
                  style={{
                    paddingLeft: "7px",
                  }}
                >
                  Add Countries
                </label>
                <div className="col-12 col-sm-8 grid-col-side-padding-countries">
                  <Field
                    as="select"
                    name="countryCode"
                    className={`form-select primary-focus primary-form-control ${
                      touched.countryCode && errors.countryCode
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="" disabled>
                      Select countries
                    </option>
                    {dropdownCountries.map((option) => (
                      <option key={option._id} value={option.countryCode}>
                        {option.countryName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="countryCode"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="col-12 col-sm-4 px-0 d-flex">
                  <button
                    type="submit"
                    className="btn countries-primary-btn w-100"
                    style={{
                      height: "38px",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <CountriesListTable
        countries={countries}
        onDelete={handleCountryDelete}
        onChangeStatus={() => {}}
      />
    </>
  );
};

export default Countries;
