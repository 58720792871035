import CloseIcon from "@mui/icons-material/Close";
import { Button, MenuItem, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    paddingTop: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxHeight: "calc(100vh - 55px)",
    width: "100%",
  },
}));

export default function PlacesManagementDialog({
  open,
  handleClose,
  title,
  onAdd,
  editValues,
  setEditValues,
  onEdit,
  countries,
  categories,
}) {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .max(100, "Name must be shorter than or equal to 100 characters"),
    city: Yup.string()
      .required("City is required")
      .max(100, "City must be shorter than or equal to 100 characters"),
    country: Yup.object()
      .shape({
        countryName: Yup.string().required(),
        countryCode: Yup.string(),
      })
      .required("Country is required"),
    formattedAddress: Yup.string()
      .required("Formatted Address is required")
      .max(
        500,
        "Formatted Address must be shorter than or equal to 500 characters"
      ),
    category: Yup.object()
      .shape({
        name: Yup.string().required(),
        categoryColor: Yup.string().optional(),
        categoryIcon: Yup.string().optional(),
      })
      .required("Category is required"),
    latitude: Yup.number().required("Latitude is required"),
    longitude: Yup.number().required("Longitude is required"),
    // aliases: Yup.number().required("Longitude is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      city: "",
      country: "",
      formattedAddress: "",
      category: "",
      latitude: "",
      longitude: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  function handleSubmit(values) {
    const placeData = {
      name: values.name,
      city: values.city,
      location: {
        type: "Point",
        coordinates: [values.longitude, values.latitude],
      },
      category: {
        categoryIcon: values.category?.icon,
        categoryColor: values.category?.color,
        name: values.category?.name,
      },
      formattedAddress: values.formattedAddress,
      country: values.country?.countryName,
      countryCode: values.country?.countryCode,
    };

    if (editValues && open) {
      onEdit(editValues?.id, placeData);
      setEditValues(null);
      handleClose();
      return;
    }

    onAdd(placeData);
    handleClose();
  }

  useEffect(() => {
    if (!open) {
      formik.resetForm();
      setEditValues(null);
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (editValues && open) {
      formik.setValues({
        name: editValues?.name,
        city: editValues?.city,
        longitude: editValues.location.coordinates[0],
        latitude: editValues.location.coordinates[1],
        category: {
          name: editValues?.category,
        },
        formattedAddress: editValues?.formattedAddress,
        country: {
          countryCode: editValues?.countryCode,
          countryName: editValues?.country,
        },
      });
    }
    // eslint-disable-next-line
  }, [editValues]);

  return (
    <BootstrapDialog
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 1 }} id="customized-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 1,
          top: 5,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box
          component="form"
          sx={{
            "& .MuiFormHelperText-root": {
              marginLeft: 0,
            },
            "& .MuiFormControl-root": {
              marginTop: "8px",
            },
          }}
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <Stack direction="row" spacing={2} mt={2}>
            <TextField
              margin="normal"
              required
              id="name"
              label="Name"
              fullWidth
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              select
              margin="normal"
              required
              id="category"
              label="Category"
              fullWidth
              name="category"
              value={formik.values.category?.name || ""}
              onChange={(e) => {
                const selectedCategory = categories.find(
                  (category) => category.name === e.target.value
                );
                formik.setFieldValue("category", selectedCategory);
              }}
              error={formik.touched.category && Boolean(formik.errors.category)}
              helperText={
                formik.touched.category && formik.errors.category?.name
              }
            >
              <MenuItem key={""} value={""}>
                Select a category
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.shortId} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack direction="row" spacing={2} mt={2}>
            <TextField
              margin="normal"
              required
              id="formattedAddress"
              label="Formatted Address"
              fullWidth
              name="formattedAddress"
              value={formik.values.formattedAddress}
              onChange={formik.handleChange}
              error={
                formik.touched.formattedAddress &&
                Boolean(formik.errors.formattedAddress)
              }
              helperText={
                formik.touched.formattedAddress &&
                formik.errors.formattedAddress
              }
            />
          </Stack>
          <Stack direction="row" spacing={2} mt={2}>
            <TextField
              select
              margin="normal"
              required
              id="country"
              label="Country"
              fullWidth
              name="country"
              value={formik.values.country?.countryCode || ""}
              onChange={(e) => {
                const selectedCountry = countries.find(
                  (country) => country.countryCode === e.target.value
                );
                formik.setFieldValue("country", selectedCountry);
              }}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={
                formik.touched.country && formik.errors.country?.countryName
              }
            >
              <MenuItem key={""} value={""}>
                Select a country
              </MenuItem>
              {countries.map((country) => (
                <MenuItem key={country._id} value={country.countryCode}>
                  {country.countryName}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="normal"
              required
              id="city"
              label="City"
              fullWidth
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Stack>
          <Stack direction="row" spacing={2} mt={2}>
            <TextField
              margin="normal"
              required
              id="latitude"
              label="Latitude"
              fullWidth
              name="latitude"
              value={formik.values.latitude}
              onChange={formik.handleChange}
              error={formik.touched.latitude && Boolean(formik.errors.latitude)}
              helperText={formik.touched.latitude && formik.errors.latitude}
            />
            <TextField
              margin="normal"
              required
              id="longitude"
              label="Longitude"
              fullWidth
              name="longitude"
              value={formik.values.longitude}
              onChange={formik.handleChange}
              error={
                formik.touched.longitude && Boolean(formik.errors.longitude)
              }
              helperText={formik.touched.longitude && formik.errors.longitude}
            />
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="submit" variant="contained" sx={{ mt: 1 }}>
              Submit
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
}
