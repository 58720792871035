export const toTitleCase = (toTransform) => {
  if (!toTransform) {
    return;
  }
  return toTransform.replace(/\b([a-z])/g, function (_, initial) {
    return initial.toUpperCase();
  });
};

export const transformOptionsData = (data, labelKey) => {
  return data.map(({ id, [labelKey]: label }) => ({
    value: id,
    label,
  }));
};

export const generateNumberArray = (length) => {
  return Array.from({ length }, (_, index) => index + 1);
};

export const formattedDate = (dateString) => {
  // Check if the dateString is empty or not a string
  if (!dateString || typeof dateString !== "string") {
    return "Invalid date";
  }

  // Create a Date object from the dateString
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return "Invalid date";
  }

  // Format the date to dd/mm/yyyy
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-GB", options);

  return formattedDate;
};

export function formatNumber(number) {
  // Convert the number to a string
  let numberString = number.toString();

  // Determine the target length (6 or 7 digits)
  const targetLength = number < 1000000 ? 6 : 7;

  // Pad the string with leading zeros if necessary
  numberString = numberString.padStart(targetLength, "0");

  return numberString;
}

export function formatFilterDate(date) {
  if (date && !isNaN(date.getTime())) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return ""; // Return empty string if the date is null or invalid
}

export function convertGeojsonToArrayOfObjects(shape) {
  let arrayOfObjects = [];
  if (!shape?.location?.coordinates) return;

  if (shape?.type === "polygon") {
    const coordinates = shape?.location?.coordinates[0]; // Assuming it's a simple polygon

    // Converting coordinates into an array of objects
    arrayOfObjects = coordinates.map((coord) => {
      return { lat: coord[0], lng: coord[1] };
    });
  } else {
    // Assuming ?.location?.coordinates is the array of points representing the rectangle
    const points = shape?.location?.coordinates[0];

    // Extracting coordinates of the rectangle's corners
    const sw = new window.google.maps.LatLng(points[0][0], points[0][1]); // Bottom-left corner
    const ne = new window.google.maps.LatLng(points[2][0], points[2][1]); // Top-right corner

    // Creating LatLngBounds from the corners
    const bounds = new window.google.maps.LatLngBounds(sw, ne);

    arrayOfObjects.push({
      north: bounds.getNorthEast().lat(),
      south: bounds.getSouthWest().lat(),
      east: bounds.getNorthEast().lng(),
      west: bounds.getSouthWest().lng(),
    });
  }

  return arrayOfObjects;
}

export function convertToPolygon(data) {
  let polygon = [];
  if (data?.type === "rectangle") {
    const sw = {
      lat: data?.coordinates[0].south,
      lng: data?.coordinates[0].west,
    };
    const ne = {
      lat: data?.coordinates[0].north,
      lng: data?.coordinates[0].east,
    };

    polygon = [
      [
        [sw.lat, sw.lng], // Bottom-left corner [longitude, latitude]
        [ne.lat, sw.lng], // Bottom-right corner [longitude, latitude]
        [ne.lat, ne.lng], // Top-right corner [longitude, latitude]
        [sw.lat, ne.lng], // Top-left corner [longitude, latitude]
        [sw.lat, sw.lng], // Closing the polygon [longitude, latitude]
      ],
    ];
  } else {
    let paths = [];
    data?.coordinates?.forEach((path) => {
      paths.push([path.lat, path.lng]);
    });
    polygon = [paths];
  }

  return polygon;
}
