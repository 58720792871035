import React from "react";

const CompanyUsersCard = ({ color, data }) => {
  const { _id: id, companyName = "", msgs = 0 } = data ?? {};
  return (
    <div key={id} className="admin-dashboard-comapny-card dashboard-company-card-py " style={{ background: color }}>
      <div className="d-flex align-items-center gap-2 dashboard-company-card-px">
        <div className="dashboard-company-avatar" style={{ backgroundColor: "#fff" }}>
          {/* remove style element after placing image tag in it */}
          {/* <img src="" alt="" class="w-100 h-100" /> */}
        </div>
        <div>
          <span className="dashboard-company-name">{companyName ? companyName : "Company Name"}</span>
          {/* <span className="dashboard-company-location">Germany</span> */}
        </div>
      </div>
      <div className="mt-5" style={{ background: "rgba(217, 217, 217, 0.51)" }}>
        <div className="row w-100 m-0">
          <div className="col-4 dashboard-company-interaction-text">MSGs</div>
          <div className="col-4 dashboard-company-interaction-text">MAU</div>
          <div className="col-4 dashboard-company-interaction-text">PUSH</div>
        </div>
      </div>
      <div className="mt-4">
        <div className="row w-100 m-0">
          <div className="col-4 dashboard-company-interaction-text">{msgs}</div>
          <div className="col-4 dashboard-company-interaction-text">125.54K</div>
          <div className="col-4 dashboard-company-interaction-text">4.4 M</div>
        </div>
      </div>
    </div>
  );
};

export default CompanyUsersCard;
