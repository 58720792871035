import moment from "moment";
import React from "react";
import { AiFillDelete } from "react-icons/ai";
// import { MdEdit } from "react-icons/md";
const SubAdminListTable = ({ users = [], onChangeStatus, onDelete = () => {}, onEdit = () => {} }) => {
  return (
    <div className="mt-5 pt-4">
      <p className="fs-24 fw-400 text-black mb-4">Admins List</p>
      {users.length ? (
        <table className="table">
          <thead>
            <tr>
              <th className="user-header-item">Admin name</th>
              <th className="user-header-item">Email</th>
              <th className="user-header-item header-item-center">Account Status</th>
              <th className="user-header-item header-item-center">Roles</th>
              {/* <th className="user-header-item header-item-center">Password</th> */}
              <th className="user-header-item header-item-center">Created on</th>
              <th className="user-header-item header-item-center">Status</th>
              <th className="user-header-item header-item-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((item) => {
              const { _id: id, firstName, lastName, email, createdAt, isActive, role } = item ?? {};
              return (
                <tr className="data-row-verticle-middle" key={id}>
                  <td className="user-data-item data-item-captilize">{firstName + " " + lastName}</td>
                  <td className="user-data-item  text-nowrap">{email}</td>
                  <td className="user-data-item data-item-center">
                    {isActive ? (
                      <span className="acc-status-badge status-badge-active"> ACTIVATED</span>
                    ) : (
                      <span className="acc-status-badge status-badge-suspended">SUSPENDED</span>
                    )}
                  </td>
                  <td className="user-data-item data-item-captilize header-item-center">{role.type}</td>

                  <td className="user-data-item data-item-center">
                    {moment(createdAt).format("MMM D, YYYY [at] HH:MM A")}
                  </td>
                  <td className="bg-transparent">
                    <select
                      id={id}
                      className="form-select primary-form-control"
                      onChange={onChangeStatus}
                      defaultValue={isActive ? "active" : "suspended"}>
                      <option value="active">Active</option>
                      <option value="suspended">Suspended</option>
                    </select>
                  </td>
                  <td className="user-data-item data-item-center ">
                    <button
                      className="btn"
                      onClick={() => {
                        onDelete(id);
                      }}>
                      <AiFillDelete />
                    </button>
                    {/* <button
                      className="btn"
                      onClick={() => {
                        onEdit(item);
                      }}>
                      <MdEdit />
                    </button> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>No matching result found</div>
      )}
    </div>
  );
};

export default SubAdminListTable;
