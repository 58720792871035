import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


const PlaceFilter = ({ open, onClose, onSearch }) => {
  const [country, setCountry] = useState('');
  const [formattedAddress, setFormattedAddress] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');

  const handleClear = () => {
    setCountry('');
    setFormattedAddress('');
    setLongitude('');
    setLatitude('');
    // onClose();
  };

  const handleSearch = () => {
    const location = longitude && latitude ? `${longitude.trim()},${latitude.trim()}` : '';
    onSearch({
      country,
      formattedAddress,
      location
    });
    handleClose();
  };

  
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Filter Places</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 1,
          top: 5,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Search by Country"
          type="text"
          fullWidth
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        />
        {/* <TextField
          margin="dense"
          label="Search by Formatted Address"
          type="text"
          fullWidth
          value={formattedAddress}
          onChange={(e) => setFormattedAddress(e.target.value)}
        /> */}
        <TextField
          margin="dense"
          label="Search by Longitude"
          type="text"
          fullWidth
          value={longitude}
          onChange={(e) => setLongitude(e.target.value)}
        />

        <TextField
          margin="dense"
          label="Search by Latitude"
          type="text"
          fullWidth
          value={latitude}
          onChange={(e) => setLatitude(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClear } color="secondary">
          Clear
        </Button>
        <Button onClick={handleSearch} color="primary">
          Search
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlaceFilter;
