import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import GeoMap from "./GeoMap";
import { convertGeojsonToArrayOfObjects } from "../../../utils/helper-methods";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    paddingTop: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxHeight: "calc(100vh - 55px)",
  },
}));

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  name2: Yup.string().required("Name2 is required"),
  pivotPoint: Yup.array().of(Yup.number()).notRequired(), // Assuming pivotPoint contains numbers
  formattedAddress: Yup.string().notRequired(),
  coordinates: Yup.array().min(1, "Coordinates are required").required(),
  type: Yup.string().required("Type is required"),
});

export default function MapDialog({
  open,
  handleClose,
  center,
  title,
  onAdd,
  editValues,
  setEditValues,
  viewValues,
  setViewValues,
  onEdit,
  
}) {
  const formik = useFormik({
    initialValues: {
      name: "",
      name2: "",
      pivotPoint: [],
      formattedAddress: "",
      coordinates: [],
      type: "",
      isPivotEnabled: false,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  function handleSubmit(values) {
    const { isPivotEnabled } = values;
    if (editValues && open) {
      onEdit(editValues?.id, values);
      setEditValues(null);
      handleClose();
      return;
    }
    if (!isPivotEnabled) {
      delete values.pivotPoint;
    }

    onAdd(values);
    handleClose();
  }

  useEffect(() => {
    if (!open) {
      formik.resetForm();
      setEditValues(null);
      setViewValues(null);
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (viewValues && open) {
      formik.setValues({
        name: viewValues?.name,
        name2: viewValues?.name2,
        formattedAddress: viewValues?.formattedAddress,
        coordinates: convertGeojsonToArrayOfObjects(viewValues),
        pivotPoint: viewValues?.pivotPoint,
        type: viewValues?.type,
        isPivotEnabled:
          viewValues?.pivotPoint && viewValues?.pivotPoint?.length > 0
            ? true
            : false,
      });
      return;
    } else if (editValues && open) {
      formik.setValues({
        name: editValues?.name,
        name2: editValues?.name2,
        formattedAddress: editValues?.formattedAddress,
        coordinates: convertGeojsonToArrayOfObjects(editValues),
        pivotPoint: editValues?.pivotPoint,
        type: editValues?.type,
        isPivotEnabled:
          editValues?.pivotPoint && editValues?.pivotPoint?.length > 0
            ? true
            : false,
      });
    }
    // eslint-disable-next-line
  }, [viewValues, editValues]);

  return (
    <BootstrapDialog
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 1 }} id="customized-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 1,
          top: 5,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box
          component="form"
          sx={{
            "& .MuiFormHelperText-root": {
              marginLeft: 0,
            },
            "& .MuiFormControl-root": {
              marginTop: "8px",
            },
          }}
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <Stack direction="row" spacing={2} mt={2}>
            <TextField
              margin="normal"
              required
              id="name"
              label="Name"
              fullWidth
              name="name"
              autoFocus
              disabled={viewValues ? true : false}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              margin="normal"
              required
              id="name2"
              label="Name2"
              fullWidth
              name="name2"
              autoFocus
              disabled={viewValues ? true : false}
              value={formik.values.name2}
              onChange={formik.handleChange}
              error={formik.touched.name2 && Boolean(formik.errors.name2)}
              helperText={formik.touched.name2 && formik.errors.name2}
            />
          </Stack>
          <TextField
            margin="normal"
            required
            fullWidth
            name="formattedAddress"
            label="Formatted Address"
            type="text"
            id="formattedAddress"
            value={formik.values.formattedAddress}
            disabled={viewValues ? true : false}
            onChange={formik.handleChange}
            error={
              formik.touched.formattedAddress &&
              Boolean(formik.errors.formattedAddress)
            }
            helperText={
              formik.touched.formattedAddress && formik.errors.formattedAddress
            }
          />

          <Box sx={{ mt: 1 }}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    name="isPivotEnabled"
                    checked={formik.values.isPivotEnabled}
                    {...formik.getFieldProps("isPivotEnabled")}
                    disabled={viewValues ? true : false}
                  />
                }
                label="Pivot Required"
                labelPlacement="start"
                sx={{ m: 0 }}
              />
            </FormGroup>
          </Box>

          <Divider />

          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ m: 0.2, p: 0.2 }}
            color="#adb5bd"
          >
            {viewValues
              ? "You are in view only mode"
              : "Click once to add pivot point and twice to draw polygon*."}
          </Typography>

          <div
            style={{
              marginTop: "8px",
            }}
          />
          <GeoMap
            formik={formik}
            center={center}
            paths={
              convertGeojsonToArrayOfObjects(editValues) ||
              convertGeojsonToArrayOfObjects(viewValues)
            }
            pivotPoint={formik.values?.pivotPoint}
            isPivotEnabled={formik.values.isPivotEnabled}
            isEditMode={editValues ? true : false}
          />
          {formik.touched.coordinates && formik.errors.coordinates && (
            <p
              style={{
                margin: 0,
                color: "#d32f2f",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "0.75rem",
                lineHeight: 1.66,
                letterSpacing: "0.03333em",
                textAlign: "left",
                marginTop: "3px",
              }}
            >
              {formik.errors.coordinates}
            </p>
          )}
          {!viewValues && (
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 1, alignSelf: "flex-end" }}
              >
                Submit
              </Button>
            </Box>
          )}
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
}
