import { enqueueSnackbar } from "notistack";
import { Fragment, useCallback, useMemo } from "react";
import { createTrialPlan, updateTrialPlan } from "../../../utils/apis";
import { ENUM_PLAN_TYPE } from "../constants/plan.enum.constants";

const TrialPlanSetup = ({
  fetchedPlans,
  fetchedServices,
  renderPlan,
  setFetchedPlans,
  isEdit,
  setIsEdit,
  formData,
  setFormData,
  setShowPlan,
  showPlan,
}) => {
  const handleShowPlan = useCallback(() => {
    setShowPlan(!showPlan);
    setIsEdit(false);
  }, [setIsEdit, setShowPlan, showPlan]);

  const handleMultipleChange = useCallback(
    (e) => {
      const options = e.target.options;
      const values = [];
      for (let i = 0, l = options.length; i < l; i++) {
        if (options[i].selected && options[i].value) {
          values.push(options[i].value);
        }
      }
      setFormData({ ...formData, services: values });
    },
    [formData, setFormData]
  );

  const isTrialPlanAvailable = useMemo(() => {
    const trialPlans = fetchedPlans?.filter(
      (plan) => plan?.planType === ENUM_PLAN_TYPE.TRIAL
    );
    if (trialPlans?.length > 0) {
      return true;
    }
    return false;
  }, [fetchedPlans]);

  const renderAddButton = useCallback(() => {
    if (!isTrialPlanAvailable) {
      if (!showPlan) {
        return (
          <button className="btn btn-add-plan ms-5" onClick={handleShowPlan}>
            +
          </button>
        );
      } else {
        return (
          <button className="btn btn-add-plan ms-5" onClick={handleShowPlan}>
            -
          </button>
        );
      }
    }
  }, [handleShowPlan, isTrialPlanAvailable, showPlan]);

  const clearFormData = useCallback(() => {
    setFormData({
      name: "",
      services: [],
      requestLimit: "",
      timeWindow: "",
      isDefault: false,
      shortId: "",
    });
  }, [setFormData]);

  const handleAddClick = async () => {
    if (
      !formData.name ||
      !formData.requestLimit ||
      !formData.timeWindow ||
      !formData.services.length
    ) {
      enqueueSnackbar(`All fields are required.`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      let res = null;
      if (isEdit) {
        res = await updateTrialPlan(formData, enqueueSnackbar);
      } else {
        let updatedFormData = { ...formData };
        updatedFormData.isDefault = true;
        res = await createTrialPlan(updatedFormData, enqueueSnackbar);
      }
      if (res.success) {
        if (isEdit) {
          // Real time data update
          let planToUpdateId = fetchedPlans?.findIndex(
            (item) => item.shortId === formData.shortId
          );
          let planToUpdate = fetchedPlans[planToUpdateId];
          planToUpdate = { ...planToUpdate, ...res.plan };
          fetchedPlans[planToUpdateId] = planToUpdate;
          setFetchedPlans([...fetchedPlans]);
        } else {
          setFetchedPlans([...fetchedPlans, res.plan]);
        }
        setIsEdit(false);
        clearFormData();
        setShowPlan(false);
      }
    }
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <div className="fs-24 fw-600 fribe-text-dark">Trial Plans</div>
        {renderAddButton()}
      </div>
      {showPlan ? (
        <div className="row mt-3 w-100">
          <div className="col-12">
            <div className="row m-0 w-100 row-gap-3">
              <div className="col-6 grid-col-side-padding ps-0">
                <label
                  htmlFor="planName"
                  className="form-label primary-form-label"
                >
                  Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                  placeholder="Ex. Standard"
                  className="form-control primary-form-control"
                  id="planName"
                />
              </div>
              <div className="col-6 grid-col-side-padding ps-0">
                <label
                  htmlFor="planRequestLimit"
                  className="form-label primary-form-label"
                >
                  Request limit
                </label>
                <input
                  type="number"
                  value={formData.requestLimit}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      requestLimit: e.target.value,
                    }))
                  }
                  placeholder="Ex. 1000"
                  className="form-control primary-form-control"
                  id="planRequestLimit"
                />
              </div>
              <div className="col-6 grid-col-side-padding">
                <label
                  htmlFor="planTimeWindow"
                  className="form-label primary-form-label"
                >
                  Time window
                </label>
                <select
                  id="planTimeWindow"
                  value={formData.timeWindow}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      timeWindow: e.target.value,
                    }))
                  }
                  className="form-select"
                  aria-label="Time window"
                >
                  <option value="" disabled>
                    Choose time window
                  </option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>
              <div className="col-6 grid-col-side-padding ps-0">
                <label
                  htmlFor="planServices"
                  className="form-label primary-form-label"
                >
                  Service
                </label>
                <select
                  id="planServices"
                  defaultValue={formData.services}
                  onChange={handleMultipleChange}
                  className="form-select"
                  aria-label="Time window"
                  multiple
                >
                  <option value="" disabled>
                    Choose a service
                  </option>
                  {fetchedServices.map((service) => {
                    return (
                      <option key={service._id} value={service.type}>
                        {service.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="mt-5">
              <button
                className="btn fribe-primary-btn modify-location-btn-update"
                onClick={handleAddClick}
              >
                {isEdit ? "Update Record" : "Add"}
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <div className="row mt-5">{renderPlan("TRIAL")}</div>
    </Fragment>
  );
};

export default TrialPlanSetup;
