import React, { useState, useEffect } from "react";
import UserIcon from "../../assets/images/user.png";
import SubscriberIcon from "../../assets/images/subscriber.png";
import CompanyUsersCard from "./CompanyUsersCard";
import Carousel from "react-multi-carousel";
import { useSnackbar } from "notistack";
import { fetchAdminDashboardData } from "../../utils/apis";

const backgroundColors = ["#75cdff", "#b275ff", "#ff9675", "#FFE975"];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Dashboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      const data = await fetchAdminDashboardData(enqueueSnackbar);
      if (data) setDashboardData(data);
    };
    fetchDashboardData();
  }, [enqueueSnackbar]);

  const analysisCardsInfo = [
    {
      title: "Suscribers",
      background: "linear-gradient(180deg, #03cc9c 0%, rgba(0, 217, 178, 0.71) 100%)",
      topColor: "#08a781",
      icon: SubscriberIcon,
      iconWidth: "90px",
      iconHeight: "90px",
      dataKey: "subscribers",
    },
    {
      title: "Trial Users",
      background: "linear-gradient(180deg, #039ccc 0%, rgba(0, 191, 217, 0.71) 100%)",
      topColor: "#088aa7",
      icon: UserIcon,
      iconWidth: "68px",
      iconHeight: "63px",
      dataKey: "trialUsers",
    },
    {
      title: "Expired Users",
      background: "linear-gradient(180deg, #b61010 0%, rgba(241, 6, 6, 0.74) 100%)",
      topColor: "#e42323",
      dataKey: "expiredUsers",
    },
  ];
  const messageCardsInfo = [
    { title: "Daily Messages", topColor: "#9a40f3", dataKey: "dailyMessages" },
    { title: "Monthly Messages", topColor: "#9a40f3", dataKey: "monthlyMessages" },
    { title: "Yearly Messages", topColor: "#9a40f3", dataKey: "yearlyMessages" },
  ];

  const paymentCardsInfo = [
    { title: "Paid Amounts", bgColor: "#46bd67", topColor: "#06a031", dataKey: "paidAmount" },
    { title: "Pending Payments", bgColor: "#adaf4a", topColor: "#c8cc12", dataKey: "pendingAmount" },
    { title: "Failed Subscribtion", bgColor: "#f0748a", topColor: "#f34080", dataKey: "failedSubscriptions" },
  ];
  return (
    <>
      <p className="fs-24 fw-700 text-black">Top company users</p>
      {dashboardData?.topCompanyUsers?.length > 0 && (
        <div className="admin-dashboard-card-layout-wrapper" style={{ width: "100%" }}>
          <Carousel itemClass="px-2" containerClass="carousel-container" partialVisbile responsive={responsive}>
            {dashboardData.topCompanyUsers.map((item, index) => (
              <CompanyUsersCard key={item._id} data={item} color={backgroundColors[index % backgroundColors.length]} />
            ))}
          </Carousel>
        </div>
      )}

      <p className="fs-22 fw-700 text-black mt-3">Analysis</p>

      <div className="admin-dashboard-card-layout-wrapper">
        {analysisCardsInfo.map((card) => (
          <div
            key={card.title}
            className="dashboard-analysis-card"
            style={{ height: 240, background: card.background }}>
            <div className="dashboard-analysis-card-top" style={{ backgroundColor: card.topColor }}>
              {card.title}
            </div>
            {card.icon && (
              <div>
                <img src={card.icon} alt={card.title} width={card.iconWidth} height={card.iconHeight} />
              </div>
            )}
            <div className={`dashboard-analysis-interaction-text ${card.icon ? "" : "mt-3"}`}>
              {dashboardData?.[card.dataKey]}
            </div>
          </div>
        ))}
      </div>

      <div className="admin-dashboard-card-layout-wrapper mt-4">
        {messageCardsInfo.map((card) => (
          <div key={card.title} className="dashboard-analysis-card dashboard-analysis-msg-card-bg-height">
            <div className="dashboard-analysis-card-top" style={{ backgroundColor: card.topColor }}>
              {card.title}
            </div>
            <div className="fs-20 fw-700 text-white">{dashboardData?.[card.dataKey]}</div>
          </div>
        ))}
      </div>

      <div className="admin-dashboard-card-layout-wrapper mt-4">
        {paymentCardsInfo.map((card) => (
          <div
            key={card.title}
            className="dashboard-analysis-card"
            style={{ height: 110, backgroundColor: card.bgColor }}>
            <div className="dashboard-analysis-card-top" style={{ backgroundColor: card.topColor }}>
              {card.title}
            </div>
            <div className="fs-24 fw-700 text-white">{dashboardData?.[card.dataKey]}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Dashboard;
