import moment from "moment";
import React from "react";
import { AiFillDelete } from "react-icons/ai";
// import { MdEdit } from "react-icons/md";
const CountriesListTable = ({
  countries = [],
  onChangeStatus,
  onDelete = () => {},
  onEdit = () => {},
}) => {
  return (
    <div className="mt-5 pt-4">
      <p className="fs-24 fw-400 text-black mb-4">Countries List</p>
      {countries.length ? (
        <table className="table">
          <thead>
            <tr>
              <th className="user-header-item">Country Name</th>
              <th className="user-header-item">Country Code</th>
              <th className="user-header-item header-item-center">createdAt</th>
              <th className="user-header-item header-item-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {countries.map((item) => {
              const {
                _id: id,
                countryName,
                countryCode,
                createdAt,
              } = item ?? {};
              return (
                <tr className="data-row-verticle-middle" key={id}>
                  <td className="user-data-item data-item-captilize">
                    {countryName}
                  </td>
                  <td className="user-data-item text-nowrap">{countryCode}</td>
                  <td className="user-data-item data-item-center">
                    {moment(createdAt).format("MMM D, YYYY [at] HH:MM A")}
                  </td>
                  <td className="user-data-item data-item-center ">
                    <button
                      className="btn"
                      onClick={() => {
                        onDelete(id);
                      }}
                    >
                      <AiFillDelete />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>No matching result found</div>
      )}
    </div>
  );
};

export default CountriesListTable;
