import React, { useEffect, useState } from "react";
import SubAdminListTable from "./SubAdminListTable";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  fetchRoles,
  createSubAdmin,
  fetchUsersListsData,
  activateUser,
  inactivateUser,
} from "../../utils/apis";
import { useSnackbar } from "notistack";
import { deleteUser } from "../../utils/apis";
import { confirmAlert } from "../../utils/sweet-alert-fire";

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  companyName: "",
  mobileNumber: "",
  role: "",
  password: "",
  confirmPassword: "",
  permissions: [],
  signUpFrom: "LOCAL",

  dashboardCheck: false,
  chatSupportCheck: false,
  usersListCheck: false,
  planSetupCheck: false,
  locationCheck: false,
  billingCheck: false,
  adminCheck: false,
  analyticCheck: false,
  servicesCheck: false,
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().nullable(),
  companyName: Yup.string().nullable(),
  role: Yup.string().required("Role is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
const checkboxToPermissionMap = {
  dashboardCheck: "dashboard",
  chatSupportCheck: "chat_support",
  usersListCheck: "users_list",
  planSetupCheck: "plan_setup",
  locationCheck: "locations",
  billingCheck: "billing",
  adminCheck: "admins",
  analyticCheck: "analytics",
  servicesCheck: "services",
  // Add more mappings for other checkboxes as needed
};

const SubAdmin = () => {
  const { enqueueSnackbar } = useSnackbar();
  // const [formInitialObjValue, setFormInitalObjValue] = useState(initialValues);

  const [roles, setRoles] = useState([]);
  const [adminsList, setAdminList] = useState([]);
  const [enabelRoleFetch, setEnabelRoleFetch] = useState(true);
  const [enableAdminListFetch, setEnableAdminListFetch] = useState(true);
  useEffect(() => {
    if (enabelRoleFetch) {
      (async function () {
        const result = await fetchRoles(enqueueSnackbar);
        if (result.statusCode === 200) {
          setRoles(result.data);
        }
      })();
    }
    return setEnabelRoleFetch(false);
  }, [enabelRoleFetch, setEnabelRoleFetch, enqueueSnackbar]);
  useEffect(() => {
    if (enableAdminListFetch) {
      (async function () {
        const filters = {
          type: "ADMIN,SUPER_ADMIN",
        };
        const result = await fetchUsersListsData(filters, enqueueSnackbar);

        setAdminList(result);
      })();
    }
    return setEnableAdminListFetch(false);
  }, [enableAdminListFetch, setEnableAdminListFetch, enqueueSnackbar]);

  const handleStatusChange = async (e) => {
    if (e.target.value === "active") {
      const res = await activateUser(e.target.id, enqueueSnackbar);
      const clonseUsersList = [...adminsList];
      const userIndex = clonseUsersList.findIndex(
        (user) => user._id === res._id
      );
      clonseUsersList[userIndex].isActive = res.isActive;
      setAdminList(clonseUsersList);
    } else {
      const res = await inactivateUser(e.target.id, enqueueSnackbar);
      const clonseUsersList = [...adminsList];
      const userIndex = clonseUsersList.findIndex(
        (user) => user._id === res._id
      );
      clonseUsersList[userIndex].isActive = res.isActive;
      setAdminList(clonseUsersList);
    }
  };

  const handleCheckboxChange = (e, permissions, setFieldValue) => {
    const { name, checked } = e.target;

    // Get the permission value from the mapping
    const permissionValue = checkboxToPermissionMap[name];

    if (checked) {
      // Add the permission value to the permissions array
      setFieldValue("permissions", [...permissions, permissionValue]);
    } else {
      // Remove the permission value from the permissions array
      setFieldValue(
        "permissions",
        permissions.filter((permission) => permission !== permissionValue)
      );
    }

    // Update the checkbox value in the form state
    setFieldValue(name, checked);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const formData = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      companyName: values.companyName,
      role: values.role,
      password: values.password,

      permissions: values.permissions,
      signUpFrom: values.signUpFrom,
      type: values.type,
    };

    // Handle form submission here, using the formData object

    const result = await createSubAdmin(formData, enqueueSnackbar);

    if (result?.data?.statusCode === 201) {
      enqueueSnackbar("Sub Admin created Successfully", { variant: "success" });
      setEnableAdminListFetch(true);
    }
    resetForm();
    setSubmitting(false);
  };

  const handleDelete = async (id) => {
    const proceed = await confirmAlert();
    if (!proceed) return;

    const response = await deleteUser(id, enqueueSnackbar);

    if (response?.status === 200) {
      setEnableAdminListFetch(true);
      enqueueSnackbar(`Sub Admin deleted succesfully`, {
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, touched, values, setFieldValue }) => (
          <Form>
            <div>
              <p className="fs-24 fw-400 text-black">+ Add sub admin:</p>
              <div className="d-flex align-items-center flex-wrap gap-4">
                <div
                  className="light-dark-bg-card p-3 px-xl-4 pb-5"
                  style={{ flex: 1, minWidth: 560 }}
                >
                  <p className="fs-20 fw-500 text-black">Details:</p>
                  <div className="row m-0 col-xxl-11 row-gap-3">
                    <div className="col-6">
                      <Field
                        type="text"
                        name="firstName"
                        className={`form-control form-control-lg primary-form-control sub-admin-input-styling   ${
                          touched.firstName && errors.firstName
                            ? "is-invalid"
                            : ""
                        } `}
                        placeholder="First Name"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-6">
                      <Field
                        type="text"
                        name="lastName"
                        className={`form-control form-control-lg primary-form-control sub-admin-input-styling   ${
                          touched.lastName && errors.lastName
                            ? "is-invalid"
                            : ""
                        } `}
                        placeholder="Last Name"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-6">
                      <Field
                        type="text"
                        name="companyName"
                        className={`form-control form-control-lg primary-form-control sub-admin-input-styling   ${
                          touched.companyName && errors.companyName
                            ? "is-invalid"
                            : ""
                        } `}
                        placeholder="Company Name"
                      />
                      <ErrorMessage
                        name="companyName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-6">
                      <Field
                        type="text"
                        name="email"
                        className={`form-control form-control-lg primary-form-control sub-admin-input-styling   ${
                          touched.email && errors.email ? "is-invalid" : ""
                        } `}
                        placeholder="Email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-6">
                      <Field
                        type="text"
                        name="password"
                        className={`form-control form-control-lg primary-form-control sub-admin-input-styling   ${
                          touched.password && errors.password
                            ? "is-invalid"
                            : ""
                        } `}
                        placeholder="Password"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-6">
                      <Field
                        type="text"
                        name="confirmPassword"
                        className={`form-control form-control-lg primary-form-control sub-admin-input-styling   ${
                          touched.confirmPassword && errors.confirmPassword
                            ? "is-invalid"
                            : ""
                        } `}
                        placeholder="Confirm Password"
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="col-6 col-md-8 grid-col-side-padding ">
                      <label
                        htmlFor="modifyLocationCategory"
                        className="form-label primary-form-label"
                      >
                        Role
                      </label>
                      <Field
                        as="select"
                        name="role"
                        className={`form-select primary-focus primary-form-control ${
                          touched.role && errors.role ? "is-invalid" : ""
                        }`}
                      >
                        <option value="" disabled>
                          Select Role
                        </option>
                        {roles.map((option) => (
                          <option key={option._id} value={option._id}>
                            {option.name?.toUpperCase()}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="role"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="light-dark-bg-card p-3"
                  style={{ flex: "0 0 auto" }}
                >
                  <div className="sub-admin-check-stack-styling">
                    <div className="form-check">
                      <Field
                        type="checkbox"
                        name="dashboardCheck"
                        className="form-check-input"
                        id="dashboardCheck"
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            values.permissions,
                            setFieldValue
                          )
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="dashboardCheck"
                      >
                        Dashboard
                      </label>
                    </div>
                    <div className="form-check">
                      <Field
                        type="checkbox"
                        name="chatSupportCheck"
                        className="form-check-input"
                        id="chatSupportCheck"
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            values.permissions,
                            setFieldValue
                          )
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="chatSupportCheck"
                      >
                        Chat Support
                      </label>
                    </div>
                  </div>

                  <div className="sub-admin-check-stack-styling">
                    <div className="form-check">
                      <Field
                        type="checkbox"
                        name="usersListCheck"
                        className="form-check-input"
                        id="usersListCheck"
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            values.permissions,
                            setFieldValue
                          )
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="usersListCheck"
                      >
                        Users List
                      </label>
                    </div>
                    <div className="form-check">
                      <Field
                        type="checkbox"
                        name="planSetupCheck"
                        className="form-check-input"
                        id="planSetupCheck"
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            values.permissions,
                            setFieldValue
                          )
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="planSetupCheck"
                      >
                        Plan Setup
                      </label>
                    </div>
                  </div>

                  <div className="sub-admin-check-stack-styling">
                    <div className="form-check">
                      <Field
                        type="checkbox"
                        name="locationCheck"
                        className="form-check-input"
                        id="locationCheck"
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            values.permissions,
                            setFieldValue
                          )
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="LocationCheck"
                      >
                        Locations
                      </label>
                    </div>
                    <div className="form-check">
                      <Field
                        type="checkbox"
                        name="billingCheck"
                        className="form-check-input"
                        id="billingCheck"
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            values.permissions,
                            setFieldValue
                          )
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="BillingCheck"
                      >
                        Billing
                      </label>
                    </div>
                  </div>

                  <div className="sub-admin-check-stack-styling">
                    <div className="form-check">
                      <Field
                        type="checkbox"
                        name="adminCheck"
                        className="form-check-input"
                        id="adminCheck"
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            values.permissions,
                            setFieldValue
                          )
                        }
                      />
                      <label className="form-check-label" htmlFor="adminCheck">
                        Admins
                      </label>
                    </div>
                    <div className="form-check">
                      <Field
                        type="checkbox"
                        name="analyticCheck"
                        className="form-check-input"
                        id="analyticCheck"
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            values.permissions,
                            setFieldValue
                          )
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="analyticCheck"
                      >
                        Analytics
                      </label>
                    </div>
                  </div>
                  <div className="sub-admin-check-stack-styling">
                    <div className="form-check">
                      <Field
                        type="checkbox"
                        name="servicesCheck"
                        className="form-check-input"
                        id="servicesCheck"
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            values.permissions,
                            setFieldValue
                          )
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="servicesCheck"
                      >
                        Services
                      </label>
                    </div>
                  </div>
                </div>
                <div style={{ flex: "0 0 auto" }}>
                  <button
                    type="submit"
                    className="btn fribe-primary-btn create-sub-admin-btn"
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <SubAdminListTable
        users={adminsList}
        onChangeStatus={handleStatusChange}
        onDelete={handleDelete}
        // onEdit={handleEdit}
      />
    </>
  );
};

export default SubAdmin;
