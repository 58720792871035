import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import {
  addPolygon,
  deletePolygon,
  fetchPolygons,
  updatePolygon,
} from "../../utils/apis";
import MapDialog from "./components/Modal";
import PolygonListTable from "./components/PolygonListTable";
import { convertToPolygon } from "../../utils/helper-methods";

const getCurrentPositionPromise = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};

const AreaZoneLocation = () => {
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [polygons, setPolygons] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [fetch, setFetch] = useState(true);
  const [editValues, setEditValues] = useState(null);
  const [viewValues, setViewValues] = useState(null);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setModalTitle("Add Polygon");
    setOpen(true);
  };

  const handleModalEditClick = (id, data) => {
    const {pivotPoint,coordinates} = data ?? {}
    setModalTitle("Edit Polygon");
    setOpen(true);
    setEditValues({
      id,
      ...data,
    });
     if(pivotPoint && pivotPoint?.length > 0){
      setCenter({ lat: pivotPoint[0], lng: pivotPoint[1] });
    }else if(coordinates && coordinates?.length > 0){
      setCenter({ lat: coordinates[0]?.lat, lng: coordinates[0]?.lng });
    }
  };

  const handleModalViewClick = (id, data) => {
    const {pivotPoint,coordinates} = data ?? {}
    setModalTitle("View Polygon");
    setOpen(true);
    setViewValues({
      id,
      ...data,
    });
    if(pivotPoint && pivotPoint?.length > 0){
      setCenter({ lat: pivotPoint[0], lng: pivotPoint[1] });
    }else if(coordinates && coordinates?.length > 0){
      setCenter({ lat: coordinates[0]?.lat, lng: coordinates[0]?.lng });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePolygonAdd = async (data) => {
    try {
      let dataTosend = { ...data };
      dataTosend.location = {
        coordinates: data?.coordinates,
      };
      delete dataTosend.coordinates;
      const result = await addPolygon(dataTosend, enqueueSnackbar);
      if (result?.statusCode === 201) {
        setPolygons([...polygons, result.data.polygon]);
      }
    } catch (error) {
      error &&
        enqueueSnackbar(`${error?.message}`, {
          variant: "error",
          autoHideDuration: 3000,
        });
    }
  };

  const handlePolygonEdit = async (id, data) => {
    try {
      let dataTosend = { ...data };
      if (!Array.isArray(dataTosend?.coordinates[0])) {
        dataTosend.location = {
          coordinates: convertToPolygon(dataTosend),
        };
      } else {
        dataTosend.location = {
          coordinates: data?.coordinates,
        };
      }
      delete dataTosend.coordinates;
      const result = await updatePolygon(id, dataTosend, enqueueSnackbar);
      if (result?.statusCode === 200) {
        const index = polygons.findIndex(
          (polygon) => polygon?._id === result?.data?.polygon?._id
        );
        if (index > -1) {
          const polygonsArray = [...polygons];
          polygonsArray[index] = result?.data?.polygon;
          setPolygons(polygonsArray);
        }
      }
    } catch (error) {
      error &&
        enqueueSnackbar(`${error?.message}`, {
          variant: "error",
          autoHideDuration: 3000,
        });
    }
  };

  const handlePolygonDelete = async (id) => {
    try {
      const result = await deletePolygon(id, enqueueSnackbar);
      if (result?.statusCode === 200) {
        const filteredPolygonsList = polygons.filter(
          (polygon) => polygon?._id !== id
        );
        setPolygons(filteredPolygonsList);
      }
    } catch (error) {
      error &&
        enqueueSnackbar(`${error?.message}`, {
          variant: "error",
          autoHideDuration: 3000,
        });
    }
  };

  useEffect(() => {
    if (fetch) {
      (async function () {
        const result = await fetchPolygons(enqueueSnackbar);
        if (result.statusCode === 200) {
          setPolygons(result.data?.polygons);
        }
      })();
    }
    return setFetch(false);
  }, [fetch, setPolygons]);

  const loadMap = useCallback(async () => {
    if ("geolocation" in navigator) {
      try {
        const position = await getCurrentPositionPromise();
        const { latitude, longitude } = position.coords;
        setCenter({ lat: latitude, lng: longitude });
      } catch (error) {
        enqueueSnackbar(error, {
          variant: "error",
        });
      }
    }
  }, []);

  useEffect(() => {
    loadMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <PolygonListTable
        onAdd={handleClickOpen}
        onEdit={handleModalEditClick}
        polygons={polygons}
        onView={handleModalViewClick}
        onDelete={handlePolygonDelete}
       

      />
      <MapDialog
        viewValues={viewValues}
        setViewValues={setViewValues}
        editValues={editValues}
        setEditValues={setEditValues}
        onAdd={handlePolygonAdd}
        onEdit={handlePolygonEdit}
        center={center}
        open={open}
        handleClose={handleClose}
        title={modalTitle}
      />
    </div>
  );
};

export default AreaZoneLocation;
