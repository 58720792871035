import axios from "axios";
export const baseMapUrl = process.env.REACT_APP_MAP_URL;

const mapServer = axios.create({
  baseURL: baseMapUrl,
});

mapServer.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.access_token;
    config.headers = jwtToken
      ? {
          Authorization: `Bearer ${jwtToken}`,
        }
      : {};
    return config;
  },
  (error) => {
    console.log(error);
  }
);

// Add a response interceptor
mapServer.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (
      (error.response && error.response.status === 401) ||
      (error.response && error.response.status === 403)
    ) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      localStorage.removeItem("selectedPage");
      // Redirect the user to the login page
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default mapServer;
