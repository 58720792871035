import React, { useState } from "react";
import { MdEdit } from "react-icons/md";

import { Paper, TablePagination } from "@mui/material"; // Import TablePagination

const PlacesManagementListTable = ({
  Places = [],
  onDelete = () => { },
  onEdit = () => { },
  onAdd = () => { },
  onSelectPlace = () => { },
  handleChangeRowsPerPage = () => { },
  handleChangePage = () => { },
  onFilter = () => { },
  pagination,
}) => {
  const [selectedPlaces, setSelectedPlaces] = useState([]); // Track selected places
  const [selectAll, setSelectAll] = useState(false); // Track if all are selected

  // Handle individual place selection
  const handleSelectPlace = (id) => {
    setSelectedPlaces((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((placeId) => placeId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle select all
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedPlaces([]);
    } else {
      const allPlaceIds = Places.map((place) => place.shortId);
      setSelectedPlaces(allPlaceIds);
    }
    setSelectAll(!selectAll);
  };

  const handleDeleteSelected = () => {
    // Call the onDelete function for each selected place
    onDelete(selectedPlaces);
    // Clear selected places after deletion
    setSelectedPlaces([]);
    setSelectAll(false);
  };

  return (
    <div>
      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <p className="fs-24 fw-400 text-black mb-0">Places List</p>
          <button className="btn fribe-primary-btn" onClick={onAdd}>
            Add
          </button>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {selectedPlaces.length > 0 && (
              <button
                className="btn fribe-danger-btn"
                style={{
                  backgroundColor: "#f44336",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  padding: "8px 16px",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
                onClick={handleDeleteSelected}
              >
                Delete Selected
              </button>
            )}
          </div>

          {/* Right Side: Filter button */}
          <div className="d-flex">
            <button className="btn fribe-primary-btn" onClick={onFilter}>
              Filter

            </button>
          </div>
        </div>
      </div>

      {Places.length ? (
        <>
          <table className="table">
            <thead>
              <tr>
                <th className="user-header-item">
                  <input
                    className="user-header-item"
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                <th className="user-header-item">Name</th>
                <th className="user-header-item header-item-center">City</th>
                <th className="user-header-item header-item-center">Country</th>
                <th className="user-header-item header-item-center">
                  Formatted Address
                </th>
                <th className="user-header-item header-item-center">
                  Category
                </th>
                <th className="user-header-item header-item-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {Places.map((item) => {
                const {
                  shortId: id,
                  name,
                  city,
                  country,
                  formattedAddress,
                  category,
                  additionalInfo = {},
                } = item ?? {};

                return (
                  <tr className="data-row-verticle-middle" key={id}>
                    <td className="user-data-item data-item-captilize">
                      <input
                        type="checkbox"
                        checked={selectedPlaces.includes(id)}
                        onChange={() => handleSelectPlace(id)}
                      />
                    </td>
                    <td className="user-data-item data-item-captilize ">
                      {name}
                      {additionalInfo ? (
                        <>
                          <br />
                          <small>
                            {additionalInfo?.streetAddress},
                            {additionalInfo?.municipality}
                          </small>
                        </>
                      ) : null}
                    </td>
                    <td className="user-data-item data-item-center">{city}</td>
                    <td className="user-data-item data-item-center">
                      {country}
                    </td>
                    <td className="user-data-item data-item-center">
                      {formattedAddress}
                    </td>
                    <td className="user-data-item data-item-center">
                      {category ? category : "N/A"}
                    </td>
                    <td className="user-data-item data-item-center">
                      <button className="btn" onClick={() => onEdit(id, item)}>
                        <MdEdit />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TablePagination
              sx={{
                "& .MuiTablePagination-selectLabel": {
                  margin: 0,
                },
                "& .MuiTablePagination-displayedRows": {
                  margin: 0,
                },
              }}
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={pagination.total}
              rowsPerPage={pagination.perPage}
              page={pagination.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={{
                disabled: pagination.page === 1,
              }}
            />
          </Paper>
        </>
      ) : (
        <div>No matching result found</div>
      )}
    </div>
  );
};

export default PlacesManagementListTable;
