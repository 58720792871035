import React, { useState } from "react";
import { baseMapUrl } from "../../../utils/mapServer";

const ImageWithFallback = ({ icon }) => {
  const [isError, setIsError] = useState(false);

  const handleError = () => {
    setIsError(true);
  };

  return (
    <div className="category-icon-container">
      {isError ? (
        <span>N/A</span>
      ) : (
        <img
          className="category-icon"
          src={`${baseMapUrl}/admin/category/file?icon=${icon}`}
          alt="Icon"
          onError={handleError}
        />
      )}
    </div>
  );
};

export default ImageWithFallback;
