import React, { useEffect, useState } from "react";
import {
  createPayAsGoPlan,
  createPlan,
  fetchPlansListsData,
  fetchServicesListsData,
  updatePayAsGoPlan,
  updatePlan,
} from "../../utils/apis";
import { useSnackbar } from "notistack";
import TrialPlanSetup from "./components/TrialPlanSetup";
import { ENUM_PLAN_TYPE } from "./constants/plan.enum.constants";

const PlansSetup = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [plans, setPlans] = useState([]);
  const [services, setServices] = useState([]);
  const [showCreatePlan, setShowCreatePlan] = useState(false);
  const [showCreatePayAsGoPlan, setShowCreatePayAsGoPlan] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isPayAsGoEdit, setIsPayAsGoEdit] = useState(false);
  const [showPlan, setShowPlan] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    services: [],
    requestLimit: "",
    timeWindow: "",
    isUnlimited: false,
    isDefault: false,
    shortId: "",
  });
  const [formPayAsGoData, setFormPayAsGoData] = useState({
    name: "",
    services: [],
  });

  const fetchPlansData = async () => {
    const plansData = await fetchPlansListsData(enqueueSnackbar);
    setPlans(plansData);
  };

  const fetchServicesData = async () => {
    const servicesData = await fetchServicesListsData(enqueueSnackbar);
    setServices(servicesData);
  };

  useEffect(() => {
    fetchPlansData();
    fetchServicesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const capitalize = (str) => {
    str = str.toLowerCase().replaceAll("_", " ").replace("service", "");
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleMultipleChange = (e) => {
    const options = e.target.options;
    const values = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected && options[i].value) {
        values.push(options[i].value);
      }
    }
    setFormData({ ...formData, services: values });
  };

  const handlePayAsGoMultipleChange = (e) => {
    const options = e.target.options;
    const values = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected && options[i].value) {
        let index = formPayAsGoData.services.findIndex(
          (item) => item.type === options[i].value
        );
        values.push({
          type: options[i].value,
          price: index > -1 ? formPayAsGoData.services[index].price : 0.1,
        });
      }
    }
    setFormPayAsGoData({ ...formPayAsGoData, services: values });
  };

  const clearFormData = () => {
    setFormData({
      name: "",
      price: "",
      services: [],
      requestLimit: "",
      timeWindow: "",
      isUnlimited: false,
      isDefault: false,
      shortId: "",
    });
  };

  const handleAddClick = async () => {
    if (
      !formData.name ||
      !formData.price ||
      !formData.requestLimit ||
      !formData.timeWindow ||
      !formData.services.length
    ) {
      enqueueSnackbar(`All fields are required.`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      let res = null;
      if (isEdit) {
        res = await updatePlan(formData, enqueueSnackbar);
      } else {
        res = await createPlan(formData, enqueueSnackbar);
      }
      if (res.success) {
        if (isEdit) {
          //Real time data update
          let planToUpdateId = plans.findIndex(
            (item) => item.shortId === formData.shortId
          );
          let planToUpdate = plans[planToUpdateId];
          planToUpdate = { ...planToUpdate, ...formData };
          plans[planToUpdateId] = planToUpdate;
          setPlans([...plans]);
        } else {
          setPlans([...plans, res.plan]);
        }
        setIsEdit(false);
        clearFormData();
        setShowCreatePlan(false);
      }
    }
  };

  const handleAddPayAsGoClick = async () => {
    if (!formPayAsGoData.name || !formPayAsGoData.services.length) {
      enqueueSnackbar(`All fields are required.`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      const allObjectsHavePositivePrice = formPayAsGoData.services.every(
        (obj) => {
          return obj.hasOwnProperty("price") && obj.price > 0;
        }
      );
      if (!allObjectsHavePositivePrice) {
        enqueueSnackbar(`All Service price should be greater than zero.`, {
          variant: "error",
          autoHideDuration: 3000,
        });
        return;
      }
      let res = null;
      if (isPayAsGoEdit) {
        res = await updatePayAsGoPlan(formPayAsGoData, enqueueSnackbar);
      } else {
        res = await createPayAsGoPlan(formPayAsGoData, enqueueSnackbar);
      }
      if (res.success) {
        if (isPayAsGoEdit) {
          //Real time data update
          let planToUpdateId = plans.findIndex(
            (item) => item.shortId === formData.shortId
          );
          let planToUpdate = plans[planToUpdateId];
          planToUpdate = { ...planToUpdate, ...formData };
          plans[planToUpdateId] = planToUpdate;
          setPlans([...plans]);
        } else {
          setPlans([...plans, res.plan]);
        }
        setIsPayAsGoEdit(false);
        setFormPayAsGoData({
          name: "",
          services: [],
        });
        setShowCreatePayAsGoPlan(false);
      }
    }
  };

  const editPlan = (plan, type) => {
    if (type === ENUM_PLAN_TYPE.TRIAL) {
      setFormData({
        name: plan.name,
        services: plan.services,
        requestLimit: plan.requestLimit,
        timeWindow: plan.timeWindow,
        isDefault: plan.isDefault,
        shortId: plan.shortId,
      });
      setIsEdit(true);
      setShowPlan(true);
    }
    if (type !== ENUM_PLAN_TYPE.PAY_AS_GO) {
      setFormData({
        name: plan.name,
        price: plan.price,
        services: plan.services,
        requestLimit: plan.requestLimit,
        timeWindow: plan.timeWindow,
        isUnlimited: plan.isUnlimited,
        isDefault: plan.isDefault,
        shortId: plan.shortId,
      });
      setShowCreatePlan(true);
      setIsEdit(true);
    } else {
      setFormPayAsGoData({
        name: plan.name,
        services: plan.servicesWithPrice,
        shortId: plan.shortId,
      });
      setShowCreatePayAsGoPlan(true);
      setIsPayAsGoEdit(true);
    }
  };

  const renderPlan = (planType) => {
    let plansToSend = plans.filter((item) =>
      planType === ENUM_PLAN_TYPE.FIXED
        ? item.planType === planType || !item.planType
        : item.planType === planType
    );

    return plansToSend.map((plan) => {
      let planServices =
        planType === ENUM_PLAN_TYPE.PAY_AS_GO
          ? plan.servicesWithPrice
          : plan.services;
      return (
        <div
          className="col-sm-12 col-md-6 col-lg-6 col-xl-4 py-3"
          key={plan._id}
        >
          <div className="plan-card">
            <div className="d-flex align-items-center gap-5 p-3 m-auto">
              <span className="plan-card-heading">{plan.name}</span>
              {planType !== ENUM_PLAN_TYPE.PAY_AS_GO &&
                planType !== ENUM_PLAN_TYPE.TRIAL && (
                  <div>
                    <span className="plan-card-heading">{`$${plan.price}`}</span>
                  </div>
                )}
              <div>
                <button
                  className="btn plan-data-edit-btn"
                  onClick={() => editPlan(plan, planType)}
                >
                  Edit
                </button>
              </div>
            </div>
            <div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="plan-card-table-heading plan-table-left-item text-center">
                      Service Type
                    </th>
                    <th className="plan-card-table-heading plan-table-right-item text-center">
                      Requests Limit
                    </th>
                    {planType === ENUM_PLAN_TYPE.PAY_AS_GO && (
                      <th className="plan-card-table-heading plan-table-right-item text-center">
                        Price per Req
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {planServices.map((service) => {
                    return (
                      <tr
                        className="data-row-verticle-middle"
                        key={
                          planType === ENUM_PLAN_TYPE.PAY_AS_GO
                            ? service.name
                            : service
                        }
                      >
                        <td className="plan-card-table-data-item plan-table-left-item data-item-center text-capitalize">
                          {planType === ENUM_PLAN_TYPE.PAY_AS_GO
                            ? capitalize(service.name)
                            : capitalize(service)}
                        </td>
                        <td className="plan-card-table-data-item plan-table-right-item data-item-center">
                          {/* <button className="btn plan-data-edit-btn-float-left">Edit</button> */}
                          <span>
                            {plan.isUnlimited ? "Unlimited" : plan.requestLimit}
                          </span>
                        </td>
                        {planType === ENUM_PLAN_TYPE.PAY_AS_GO && (
                          <td className="plan-card-table-data-item plan-table-right-item data-item-center">
                            {/* <button className="btn plan-data-edit-btn-float-left">Edit</button> */}
                            <span>{service.price}</span>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="plans-setup-screen">
      <div className="d-flex align-items-center">
        <div className="fs-24 fw-600 fribe-text-dark">Fixed Price Plans</div>
        {!showCreatePlan ? (
          <button
            className="btn btn-add-plan ms-5"
            onClick={() => {
              setShowCreatePlan(true);
              clearFormData();
              setIsEdit(false);
            }}
          >
            +
          </button>
        ) : (
          <button
            className="btn btn-add-plan ms-5"
            onClick={() => {
              setShowCreatePlan(false);
              clearFormData();
              setIsEdit(false);
            }}
          >
            -
          </button>
        )}
      </div>
      {showCreatePlan ? (
        <div className="row mt-3 w-100">
          <div className="col-12">
            <div className="row m-0 w-100 row-gap-3">
              <div className="col-6 grid-col-side-padding ps-0">
                <label
                  htmlFor="planName"
                  className="form-label primary-form-label"
                >
                  Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                  placeholder="Ex. Standard"
                  className="form-control primary-form-control"
                  id="planName"
                />
              </div>
              <div className="col-6 grid-col-side-padding pe-0">
                <label
                  htmlFor="planPrice"
                  className="form-label primary-form-label"
                >
                  Price
                </label>
                <input
                  type="number"
                  value={formData.price}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      price: e.target.value,
                    }))
                  }
                  placeholder="Ex. 20"
                  className="form-control primary-form-control"
                  id="planPrice"
                />
              </div>
              <div className="col-6 grid-col-side-padding ps-0">
                <label
                  htmlFor="planRequestLimit"
                  className="form-label primary-form-label"
                >
                  Request limit
                </label>
                <input
                  type="number"
                  value={formData.requestLimit}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      requestLimit: e.target.value,
                    }))
                  }
                  placeholder="Ex. 1000"
                  className="form-control primary-form-control"
                  id="planRequestLimit"
                />
              </div>
              <div className="col-6 grid-col-side-padding pe-0">
                <label
                  htmlFor="planTimeWindow"
                  className="form-label primary-form-label"
                >
                  Time window
                </label>
                <select
                  id="planTimeWindow"
                  value={formData.timeWindow}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      timeWindow: e.target.value,
                    }))
                  }
                  className="form-select"
                  aria-label="Time window"
                >
                  <option value="" disabled>
                    Choose time window
                  </option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>
              <div className="col-6 grid-col-side-padding pe-0">
                <label
                  htmlFor="planServices"
                  className="form-label primary-form-label"
                >
                  Check List
                </label>
                <div className="light-dark-bg-card m-2 p-3">
                  <div className="sub-admin-check-stack-styling">
                    <div className="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={formData.isUnlimited}
                        id="chatSupportCheck"
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            isUnlimited: e.target.checked,
                          }))
                        }
                      />
                      <label class="form-check-label" for="chatSupportCheck">
                        Unlimited
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={formData.isDefault}
                        id="chatSupportCheck"
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            isDefault: e.target.checked,
                          }))
                        }
                      />
                      <label class="form-check-label" for="chatSupportCheck">
                        Default
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6 grid-col-side-padding ps-0">
                <label
                  htmlFor="planServices"
                  className="form-label primary-form-label"
                >
                  Service
                </label>
                <select
                  id="planServices"
                  defaultValue={formData.services}
                  onChange={handleMultipleChange}
                  className="form-select"
                  aria-label="Time window"
                  multiple
                >
                  <option value="" disabled>
                    Choose a service
                  </option>
                  {services.map((service) => {
                    return (
                      <option key={service._id} value={service.type}>
                        {service.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="mt-5">
              <button
                className="btn fribe-primary-btn modify-location-btn-update"
                onClick={handleAddClick}
              >
                {isEdit ? "Update Record" : "Add"}
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <div className="row mt-5">{renderPlan(ENUM_PLAN_TYPE.FIXED)}</div>

      <div className="d-flex align-items-center">
        <div className="fs-24 fw-600 fribe-text-dark">Pay As You Go Plans</div>
        {!showCreatePayAsGoPlan ? (
          <button
            className="btn btn-add-plan ms-5"
            onClick={() => {
              setShowCreatePayAsGoPlan(true);
              setFormPayAsGoData({ name: "", services: [] });
              setIsPayAsGoEdit(false);
            }}
          >
            +
          </button>
        ) : (
          <button
            className="btn btn-add-plan ms-5"
            onClick={() => {
              setShowCreatePayAsGoPlan(false);
              setFormPayAsGoData({ name: "", services: [] });
              setIsPayAsGoEdit(false);
            }}
          >
            -
          </button>
        )}
      </div>

      {showCreatePayAsGoPlan ? (
        <div className="row mt-3 w-100">
          <div className="col-12">
            <div className="row m-0 w-100 row-gap-3">
              <div className="col-sm-12 col-md-6 grid-col-side-padding ps-0">
                <label
                  htmlFor="planName"
                  className="form-label primary-form-label"
                >
                  Name
                </label>
                <input
                  type="text"
                  value={formPayAsGoData.name}
                  onChange={(e) =>
                    setFormPayAsGoData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                  placeholder="Ex. Standard"
                  className="form-control primary-form-control"
                  id="planName"
                />
              </div>

              <div className="col-sm-12 col-md-6 grid-col-side-padding ps-0">
                <label
                  htmlFor="planServices"
                  className="form-label primary-form-label"
                >
                  Service
                </label>
                <select
                  id="planServices"
                  value={formPayAsGoData.services.map((item) => item.type)}
                  onChange={handlePayAsGoMultipleChange}
                  className="form-select"
                  aria-label="Time window"
                  multiple
                >
                  <option value="" disabled>
                    Choose a service
                  </option>
                  {services.map((service) => {
                    return (
                      <option key={service._id} value={service.type}>
                        {service.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {formPayAsGoData.services.map((item) => {
                return (
                  <div
                    className="col-sm-12 col-md-6 grid-col-side-padding pe-0"
                    key={item.type}
                  >
                    <label
                      htmlFor="planPrice"
                      className="form-label primary-form-label"
                    >
                      {`Req Price (${item.type})`}
                    </label>
                    <input
                      type="number"
                      value={item.price}
                      onChange={(e) => {
                        let updatedServiceWithPrice = formPayAsGoData.services;
                        let index = updatedServiceWithPrice.findIndex(
                          (service) => item.type === service.type
                        );
                        updatedServiceWithPrice[index].price = e.target.value;
                        setFormData((prev) => ({
                          ...prev,
                          services: [...updatedServiceWithPrice],
                        }));
                      }}
                      placeholder="Ex. 20"
                      className="form-control primary-form-control"
                      id="planPrice"
                    />
                  </div>
                );
              })}
            </div>

            <div className="mt-5">
              <button
                className="btn fribe-primary-btn modify-location-btn-update"
                onClick={handleAddPayAsGoClick}
              >
                {isPayAsGoEdit ? "Update Record" : "Add"}
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row mt-5">{renderPlan(ENUM_PLAN_TYPE.PAY_AS_GO)}</div>

      <TrialPlanSetup
        fetchedPlans={plans}
        fetchedServices={services}
        renderPlan={renderPlan}
        setFetchedPlans={setPlans}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        setFormData={setFormData}
        formData={formData}
        setShowPlan={setShowPlan}
        showPlan={showPlan}
      />
    </div>
  );
};

export default PlansSetup;
