import CloseIcon from "@mui/icons-material/Close";
import { Button, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    paddingTop: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxHeight: "calc(100vh - 55px)",
  },
}));

export default function CategoryDialog({
  open,
  handleClose,
  title,
  onAdd,
  editValues,
  setEditValues,
  onEdit,
}) {
  const validationSchema = Yup.object().shape({
    icon: !editValues ? Yup.mixed().required("Icon is required") : Yup.mixed(), // Or use `.notRequired()` if you prefer
    name: Yup.string().required("Name is required"),
  });
  const [file, setFile] = useState(null);
  const formik = useFormik({
    initialValues: {
      name: "",
      icon: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  function handleSubmit(values) {
    if (editValues && open) {
      onEdit(editValues?.id, { name: values.name, icon: file });
      setEditValues(null);
      handleClose();
      return;
    }

    onAdd({ name: values.name, icon: file });
    handleClose();
  }

  useEffect(() => {
    if (!open) {
      formik.resetForm();
      setEditValues(null);
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (editValues && open) {
      formik.setValues({
        name: editValues?.name,
        icon: "",
      });
    }
    // eslint-disable-next-line
  }, [editValues]);

  return (
    <BootstrapDialog
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 1 }} id="customized-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 1,
          top: 5,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box
          component="form"
          sx={{
            "& .MuiFormHelperText-root": {
              marginLeft: 0,
            },
            "& .MuiFormControl-root": {
              marginTop: "8px",
            },
          }}
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <Stack direction="row" spacing={2} mt={2}>
            <TextField
              margin="normal"
              required
              id="name"
              label="Name"
              fullWidth
              name="name"
              autoFocus
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                accept: "image/*",
              }}
              margin="normal"
              type="file"
              required
              id="icon"
              label="Icon"
              fullWidth
              name="icon"
              autoFocus
              value={formik.values.icon || ""}
              onChange={(event) => {
                formik.handleChange(event);
                setFile(event.currentTarget.files[0]);
              }}
              error={formik.touched.icon && Boolean(formik.errors.icon)}
              helperText={formik.touched.icon && formik.errors.icon}
            />
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="submit" variant="contained" sx={{ mt: 1 }}>
              Submit
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
}
