import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
// import axios from "axios";

import pinIcon from "../../assets/images/pin.svg";
import { countries } from "../../helpers/countriesList";
import { baseMapUrl } from "../../utils/mapServer";

import {
  createLocation,
  fetchCategories,
  getPlacesByCoordinates,
  uploadExcel,
  exportLocations,
} from "../../utils/apis";

let markers = [];
let showLocationsGlobal = false;

const AddPlace = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [googleMap, setGoogleMap] = useState(null);
  const [googleMarker, setGoogleMarker] = useState(null);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [showLocations, setShowLocations] = useState(false);
  const [categories, setCategories] = useState([]);
  const [fetch, setFetch] = useState(true);
  const inputRef = useRef(null);
  const [exportLoader, setExportLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    formattedAddress: "",
    latitude: "",
    longitude: "",
    city: "",
    country: "",
    category: "",
    categoryIcon: "",
    color: "",
  });

  const [file, setFile] = useState(null);

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const stateToUpdate = {
      ...formData,
      [name]: value,
    };

    if (name === "category") {
      let updatedCategoryIcon = "";
      let updatedCategoryColor = "";
      // eslint-disable-next-line eqeqeq
      let index = categories.findIndex((category) => category.value == value); // Use === for comparison

      if (index >= 0) {
        updatedCategoryIcon = categories[index].icon ?? "";
        updatedCategoryColor = categories[index].color ?? "";
      }

      if (googleMarker) {
        googleMarker.setIcon({
          url: updatedCategoryIcon
            ? `${baseMapUrl}/admin/category/file?icon=${updatedCategoryIcon}`
            : pinIcon,
          scaledSize: new window.google.maps.Size(32, 32),
        });
        // Create the InfoWindow
        const infoWindow = new window.google.maps.InfoWindow({
          content: generateInfoWindowContent(
            formData.formattedAddress,
            value,
            formData.latitude,
            formData.longitude
          ),
          headerContent: formData.name || "No Title",
        });

        // Add a click event listener to the marker to show the InfoWindow
        googleMarker.addListener("mouseover", () => {
          infoWindow.open(googleMap, googleMarker);
        });
        googleMarker.addListener("mouseout", () => {
          infoWindow.close();
        });
      }

      stateToUpdate.categoryIcon = updatedCategoryIcon;
      stateToUpdate.color = updatedCategoryColor;
    }
    setFormData(stateToUpdate);
  };

  const handleFileReset = (e) => {
    setFile(null);
    inputRef.current.value = null;
  };

  const handleReset = () => {
    setFormData((prev) => {
      let country = prev.country;
      return {
        country,
        name: "",
        formattedAddress: "",
        latitude: "",
        longitude: "",
        city: "",
        category: "",
      };
    });
  };

  const handleFormSubmit = async (e) => {
    if (
      formData.name &&
      formData.longitude &&
      formData.latitude &&
      formData.formattedAddress &&
      formData.city &&
      formData.country
    ) {
      const { categoryIcon, ...requestData } = formData;
      const result = await createLocation(requestData, enqueueSnackbar);
      result.status === 201 && handleReset();
    } else {
      enqueueSnackbar("All fields are required.", { variant: "error" });
    }
  };

  const handleFileUploadServer = async () => {
    if (file) {
      setUploadLoader(true);
      const formDataFile = new FormData();
      formDataFile.append("file", file);

      try {
        const result = await uploadExcel(formDataFile, enqueueSnackbar);
        result && handleFileReset();
        setUploadLoader(false);
        enqueueSnackbar("File uploaded successfully!", { variant: "success" });
      } catch (error) {
        enqueueSnackbar(`Error uploading file: ${error}`, { variant: "error" });
      }
    } else {
      enqueueSnackbar("Please provide excel file first", { variant: "error" });
    }
  };

  const handleApiLoaded = () => {
    googleMap.addListener("click", async function (event) {
      document.querySelector("button[title=Close]")?.click();
      googleMarker.setPosition(event.latLng.toJSON());
      googleMap.panTo(event.latLng);
      await displayLocation(
        event.latLng.toJSON().lat,
        event.latLng.toJSON().lng
      );
    });
    googleMarker.addListener("click", async function (event) {
      displayLocation(
        event.latLng.toJSON().lat,
        event.latLng.toJSON().lng,
        googleMap
      );
    });
  };

  const handleUpdateAddress = async () => {
    if (formData.formattedAddress) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        { address: formData.formattedAddress },
        (results, status) => {
          if (status === "OK") {
            const location = results[0].geometry.location;
            const lat = location.lat();
            const lng = location.lng();
            googleMarker.setPosition(location);
            googleMap.panTo(location);
            displayLocation(lat, lng, googleMap);

            // Update the form data with the new coordinates
            setFormData((prevFormData) => ({
              ...prevFormData,
              latitude: lat,
              longitude: lng,
              address: formData.formattedAddress,
            }));
          } else {
            enqueueSnackbar(
              `Geocode was not successful for the following reason: ${status}`,
              {
                variant: "error",
              }
            );
          }
        }
      );
    } else {
      enqueueSnackbar("Please enter an address.", { variant: "warning" });
    }
  };

  const handleUpdateLatLng = async () => {
    const { latitude, longitude } = formData;
    if (latitude && longitude) {
      const lat = parseFloat(latitude);
      const lng = parseFloat(longitude);
      displayLocation(lat, lng, googleMap);
      const location = new window.google.maps.LatLng(lat, lng);
      googleMarker.setPosition(location);
      googleMap.panTo(location);

      setFormData((prevFormData) => ({
        ...prevFormData,
        latitude: lat,
        longitude: lng,
        address: formData.formattedAddress,
      }));
    } else {
      enqueueSnackbar("Please enter both latitude and longitude.", {
        variant: "warning",
      });
    }
  };

  const generateInfoWindowContent = (
    formatted_address,
    category,
    latitude,
    longitude
  ) => {
    return `
      <div style="
        font-family: Arial, sans-serif; 
        width: 200px; 
        padding: 10px;
        display: flex;
        flex-direction: column;
      ">
        <p style="margin: 5px 0; color: #666;">${
          formatted_address || "No Address"
        }</p>
        <p style="margin: 5px 0; color: #666;">Category: ${
          category || "No Category"
        }</p>
        <a 
          href="https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}" 
          target="_blank"
          style="
            display: inline-block; 
            margin-top: 10px; 
            color: #1a73e8; 
            text-decoration: none; 
            font-size: 14px;
          "
        >
          View on Google Maps
        </a>
      </div>
    `;
  };

  function displayLocation(latitude, longitude, map = null) {
    let geocoder = new window.google.maps.Geocoder();
    let latlng = new window.google.maps.LatLng(latitude, longitude);

    geocoder.geocode({ latLng: latlng }, async function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          let name = results[0].address_components.filter(
            (ac) => ~ac.types.indexOf("plus_code")
          )[0]?.long_name;

          const nameFromDiv = document.querySelector(
            'div[jstcache="11"].gm-title'
          );
          if (nameFromDiv) {
            name = nameFromDiv.textContent.trim();
          }

          let formatted_address = results[0].formatted_address;
          let city = results[0].address_components.filter(
            (ac) => ~ac.types.indexOf("locality")
          )[0]?.long_name;

          let country = results[0].address_components.filter(
            (ac) => ~ac.types.indexOf("country")
          )[0]?.long_name;

          setFormData((prev) => ({
            ...prev,
            name: name,
            latitude,
            longitude,
            formattedAddress: formatted_address,
            city: city?.trim(),
            country: country?.trim(),
          }));

          if (map) {
            // Create a marker at the user's location
            const marker = new window.google.maps.Marker({
              position: { lat: latitude, lng: longitude },
              map,
              icon: {
                url: formData.categoryIcon
                  ? `${baseMapUrl}/admin/category/file?icon=${formData.categoryIcon}`
                  : pinIcon,
                scaledSize: new window.google.maps.Size(32, 32),
              },
            });

            // Create the InfoWindow
            const infoWindow = new window.google.maps.InfoWindow({
              content: generateInfoWindowContent(
                formatted_address,
                formData.category,
                latitude,
                longitude
              ),
              headerContent: name || "No Title",
            });

            // Add a click event listener to the marker to show the InfoWindow
            marker.addListener("mouseover", () => {
              infoWindow.open(map, marker);
            });
            marker.addListener("mouseout", () => {
              infoWindow.close();
            });

            // Add a listener for the map idle event to fetch locations
            window.google.maps.event.addListener(
              map,
              "idle",
              async function () {
                if (showLocationsGlobal) {
                  fetchLocations(map || googleMap);
                }
              }
            );

            // Set the global map and marker
            setGoogleMap(map);
            setGoogleMarker(marker);
          }

          setFormData((prev) => {
            return {
              ...prev,
              name: name,
              latitude,
              longitude,
              formattedAddress: formatted_address,
              city: city?.trim(),
              country: country?.trim(),
            };
          });
        } else {
          enqueueSnackbar(`Address not found`, { variant: "error" });
        }
      } else {
        enqueueSnackbar(`Geocoder failed due to: ${status}`, {
          variant: "error",
        });
      }
    });
  }

  const getCurrentPositionPromise = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  const getBoundsRadius = (map) => {
    let bounds = map.getBounds();
    let r = 6378.8;
    let ne_lat = bounds.getNorthEast().lat() / 57.2958;
    let ne_lng = bounds.getNorthEast().lng() / 57.2958;
    let c_lat = bounds.getCenter().lat() / 57.2958;
    let c_lng = bounds.getCenter().lng() / 57.2958;
    let r_km =
      r *
      Math.acos(
        Math.sin(c_lat) * Math.sin(ne_lat) +
          Math.cos(c_lat) * Math.cos(ne_lat) * Math.cos(ne_lng - c_lng)
      ) *
      1000;
    return r_km;
  };

  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const fetchLocations = async (map) => {
    const filters = {
      location: `${map.getCenter().lng()},${map.getCenter().lat()}`,
      radius: getBoundsRadius(map),
    };
    const places = await getPlacesByCoordinates(filters, enqueueSnackbar);
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    if (places?.data && places?.data?.length) {
      places.data.forEach((place) => {
        if (map.getZoom() > 8) {
          const m = new window.google.maps.Marker({
            position: { lat: place.latitude, lng: place.longitude },
            map,
            icon: {
              url: place.categoryIcon
                ? `${baseMapUrl}/admin/category/file?icon=${place.categoryIcon}`
                : pinIcon,
              scaledSize: new window.google.maps.Size(32, 32),
              labelOrigin: new window.google.maps.Point(0, -10),
            },
            label: {
              text: `${place.name}${
                place.category ? ` ${place.category}` : ""
              }`, //   Text of the label
              color: place.categoryColor, // Color of the label text
              fontSize: "14px", // Size of the label text
            },
          });

          const infoWindow = new window.google.maps.InfoWindow({
            content: generateInfoWindowContent(
              place.formattedAddress,
              place.category,
              place.latitude,
              place.longitude
            ),
            headerContent: place.name || "No Title",
          });

          m.addListener("click", () => {
            infoWindow.open(map, m);

            // Update form data with place information
            setFormData({
              name: place.name || "",
              formattedAddress: place.formattedAddress || "",
              latitude: place.latitude || "",
              longitude: place.longitude || "",
              city: place.city || "",
              country: capitalizeFirstLetter(place.country) || "",
              category: place.category || "",
              categoryIcon: place.categoryIcon || "",
            });
          });

          // Optionally, add a mouseout event listener to open the info window
          m.addListener("mouseover", () => {
            infoWindow.open(map, m);
          });

          // Optionally, add a mouseout event listener to close the info window
          m.addListener("mouseout", () => {
            infoWindow.close(map, m);
          });

          markers.push(m);
        }
      });
    }
  };

  const loadMap = async (place) => {
    if ("geolocation" in navigator) {
      try {
        // Get the user's current position
        const position = await getCurrentPositionPromise();
        const { latitude, longitude } = position.coords;

        // Create a new map centered on the user's location
        const map = new window.google.maps.Map(
          document.getElementById("myMap"),
          {
            center: { lat: latitude, lng: longitude },
            zoom: 13,
            styles: [
              {
                featureType: "all",
                elementType: "labels",
                stylers: [{ visibility: "on" }],
              },
            ],
          }
        );
        // Display the current location
        displayLocation(latitude, longitude, map);
      } catch (error) {
        enqueueSnackbar(error.message || "An error occurred", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Geolocation is not supported by this browser", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    loadMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetch) {
      (async function () {
        const result = await fetchCategories(enqueueSnackbar);
        if (result.statusCode === 200) {
          // Filter only active categories
          const activeCategories =
            result?.data?.categories?.filter((category) => category.status) ||
            [];
          setCategories(activeCategories);
          setFormData((prevFormData) => ({
            ...prevFormData,
            category: result?.data?.categories[0]?.name,
          }));
        }
      })();
    }
    return () => setFetch(false); // Use a cleanup function to set fetch to false
  }, [fetch, enqueueSnackbar]);

  useEffect(() => {
    if (googleMap && googleMarker) {
      handleApiLoaded(googleMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleMarker, googleMap]);

  useEffect(() => {
    if (showLocations) {
      fetchLocations(googleMap);
    } else {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExportLocations = async () => {
    setExportLoader(true); // Show loading state
    await exportLocations(enqueueSnackbar); // Call the API
    setExportLoader(false); // Hide loading state
  };

  return (
    <div className="row m-0 w-100">
      <div className="col-6">
        <p className="fs-18 fw-500 fribe-secondary-dark-text mb-4">
          Here you can update, remove and add new location to the databases and
          it will reflects to users immediately.
        </p>
        <div className="row m-0 w-100 row-gap-3">
          <div className="col-6 grid-col-side-padding ps-0">
            <label
              htmlFor="modifyLocationTitle"
              className="form-label primary-form-label"
            >
              Title
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="form-control primary-form-control"
              id="modifyLocationTitle"
            />
          </div>
          <div className="col-6 grid-col-side-padding pe-0">
            <label
              htmlFor="modifyLocationLatitude"
              className="form-label primary-form-label"
            >
              Latitude
            </label>
            <input
              type="text"
              name="latitude"
              value={formData.latitude}
              onChange={handleChange}
              className="form-control primary-form-control"
              id="modifyLocationLatitude"
            />
          </div>
          <div className="col-6 grid-col-side-padding ps-0">
            <label
              htmlFor="modifyLocationAddress"
              className="form-label primary-form-label"
            >
              Address
            </label>
            <input
              type="text"
              name="formattedAddress"
              value={formData.formattedAddress}
              onChange={handleChange}
              className="form-control primary-form-control"
              id="modifyLocationAddress"
            />
            <button className="btn fribe-primary" onClick={handleUpdateAddress}>
              Update Marker
            </button>
          </div>
          <div className="col-6 grid-col-side-padding pe-0">
            <label
              htmlFor="modifyLocationLongtitude"
              className="form-label primary-form-label"
            >
              Longitude
            </label>
            <input
              type="text"
              name="longitude"
              value={formData.longitude}
              onChange={handleChange}
              className="form-control primary-form-control"
              id="modifyLocationLongtitude"
            />
            <button className="btn fribe-primary" onClick={handleUpdateLatLng}>
              Update Marker
            </button>
          </div>
          <div className="col-6 grid-col-side-padding">
            <label
              htmlFor="modifyLocationCity"
              className="form-label primary-form-label"
            >
              City
            </label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="form-control primary-form-control"
              id="modifyLocationCity"
            />
          </div>
          <div className="col-6 grid-col-side-padding">
            <label
              htmlFor="modifyLocationCategory"
              className="form-label primary-form-label"
            >
              Category
            </label>
            <select
              id="modifyLocationCategory"
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="form-select"
              aria-label="Time window"
            >
              {/* <option value={""} disabled hidden> */}
              {/* Category */}
              {/* </option> */}
              {categories.map((category, index) => {
                return (
                  <option key={index} value={category?.value}>
                    {category?.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-6 grid-col-side-padding">
            <label
              htmlFor="modifyLocationCountry"
              className="form-label primary-form-label"
            >
              Country name
            </label>
            <select
              id="modifyLocationCountry"
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="form-select"
              aria-label="Time window"
            >
              <option value={""} disabled>
                Choose a country
              </option>
              {countries.map((country) => {
                return (
                  <option key={country} value={country}>
                    {country}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="my-5 border-bottom pb-5 add-place-actions-grid">
          <button
            className="btn fribe-primary-btn modify-location-btn-submit"
            onClick={handleFormSubmit}
          >
            Submit
          </button>
          {/* <button className="btn fribe-primary-outline-btn">Update</button> */}
        </div>

        <div>
          <p className="fs-18 fw-600 text-black">Attach xslx or csv</p>
          <div className="d-flex align-items-center gap-3">
            <div className="input-bg-dark-light">
              {file ? file.name : ""}
              <input
                ref={inputRef}
                type="file"
                id="upload-excel"
                onChange={handleFileUpload}
              />
            </div>
            {file ? (
              <button className="btn btn-danger" onClick={handleFileReset}>
                Clear
              </button>
            ) : null}
            <button
              className="btn attached-upload-btn"
              disabled={uploadLoader}
              onClick={handleFileUploadServer}
            >
              {uploadLoader ? "Loading..." : "Upload"}
            </button>
            <button
              className="btn attached-upload-btn"
              disabled={exportLoader}
              onClick={handleExportLocations}
            >
              {exportLoader ? "Loading..." : "Export"}
            </button>
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="form-check form-switch custom-switch-lg mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="hideShowLocations"
            checked={showLocations}
            onChange={(e) => {
              setShowLocations(e.target.checked);
              showLocationsGlobal = e.target.checked;
            }}
          />
          <label className="form-check-label" htmlFor="hideShowLocations">
            {showLocations ? "Hide locations" : "Show locations"}
          </label>
        </div>
        <div id="myMap" style={{ height: "100%" }} />
      </div>
    </div>
  );
};

export default AddPlace;
