import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { addCategory, fetchCategories, updateCategory } from "../../utils/apis";
import CategoriesListTable from "./CategoriesListTable";
import CategoryDialog from "./components/Modal";

const Categories = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(true);
  const [categories, setCategories] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [editValues, setEditValues] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setModalTitle("Add Category");
    setOpen(true);
  };

  const handleModalEditClick = (id, data) => {
    setModalTitle("Edit Category");
    setOpen(true);
    setEditValues({
      id,
      ...data,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   if (fetch) {
  //     (async function () {
  //       const result = await fetchCategories(enqueueSnackbar);
  //       if (result.statusCode === 200) {
  //         setCategories(result?.data?.categories);
  //       }
  //     })();
  //   }
  //   return setFetch(false);
  // }, [fetch, enqueueSnackbar]);

  const handleCategoryEdit = async (id, data) => {
    try {
      const { name, icon } = data;
      const formData = new FormData();
      formData.append("name", name);
      formData.append("icon", icon);
      const result = await updateCategory(id, formData, enqueueSnackbar);
      if (result?.statusCode === 200) {
        const index = categories.findIndex(
          (category) => category?.shortId === result?.data?.shortId
        );
        if (index > -1) {
          const categoryArray = [...categories];
          categoryArray[index] = result?.data;
          setCategories(categoryArray);
        }
      }
    } catch (error) {
      error &&
        enqueueSnackbar(`${error?.message}`, {
          variant: "error",
          autoHideDuration: 3000,
        });
    }
  };

  const handleAddCategory = async (data) => {
    try {
      const { name, icon } = data;
      const formData = new FormData();
      formData.append("name", name);
      formData.append("icon", icon);
      const result = await addCategory(formData, enqueueSnackbar);
      if (result?.statusCode === 201) {
        setCategories([...categories, result.data]);
      }
    } catch (error) {
      error &&
        enqueueSnackbar(`${error?.message}`, {
          variant: "error",
          autoHideDuration: 3000,
        });
    }
  };
  const handleChangeStatus = async (categoryId, isActive) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) =>
        category.shortId === categoryId
          ? { ...category, status: isActive }
          : category
      )
    );
  };
  useEffect(() => {
    if (fetch) {
      (async function () {
        const result = await fetchCategories(enqueueSnackbar);
        if (result.statusCode === 200) {
          setCategories(result?.data?.categories);
        }
      })();
    }
    return setFetch(false);
  }, [fetch, enqueueSnackbar]);

  return (
    <div>
      <CategoriesListTable
        onAdd={handleClickOpen}
        onEdit={handleModalEditClick}
        handleChangeStatus={handleChangeStatus}
        categories={categories}
        onChangeStatus={() => {}}
      />
      <CategoryDialog
        editValues={editValues}
        setEditValues={setEditValues}
        onAdd={handleAddCategory}
        onEdit={handleCategoryEdit}
        open={open}
        handleClose={handleClose}
        title={modalTitle}
      />
    </div>
  );
};

export default Categories;
