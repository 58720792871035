import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createServices } from "../../utils/apis";
import { useSnackbar } from "notistack";
import { fetchServices } from "../../utils/apis";
const ENUM_SERVICE = {
  PLACES_API_SERVICE: "PLACES_API_SERVICE",
  LIVE_TRACKING_API_SERVICE: "LIVE_TRACKING_API_SERVICE",
  MAP_API_SERVICE: "MAP_API_SERVICE",
  OTP_API_SERVICE: "OTP_API_SERVICE",
  DIRECTION_API_SERVICE: "DIRECTION_API_SERVICE",
  DISTANCE_MATRIX_API_SERVICE: "DISTANCE_MATRIX_API_SERVICE",
  AREA_ZONE_API_SERVICE: "AREA_ZONE_API_SERVICE",
};

export const ENUM_SERVICE_OPTIONS = [
  { value: ENUM_SERVICE.PLACES_API_SERVICE, label: "Places API Service" },
  {
    value: ENUM_SERVICE.LIVE_TRACKING_API_SERVICE,
    label: "Live Tracking API Service",
  },
  { value: ENUM_SERVICE.MAP_API_SERVICE, label: "Map API Service" },
  { value: ENUM_SERVICE.OTP_API_SERVICE, label: "OTP API Service" },
  { value: ENUM_SERVICE.DIRECTION_API_SERVICE, label: "Direction API Service" },
  {
    value: ENUM_SERVICE.DISTANCE_MATRIX_API_SERVICE,
    label: "Distance Matrix API Service",
  },
  {
    value: ENUM_SERVICE.AREA_ZONE_API_SERVICE,
    label: "Area Zone API Service",
  },
];

const servicesInitialValues = {
  name: "",
  description: "",
  type: "",
};

const ServiceTableList = ({ services = [] }) => {
  return (
    <div className="mt-5 pt-4">
      <p className="fs-24 fw-400 text-black mb-4">Services List</p>

      {services.length ? (
        <table className="table " style={{ maxWidth: "" }}>
          <thead>
            <tr>
              <th className="user-header-item fw-600 data-item-center">
                Service Name
              </th>
              <th
                className="user-header-item fw-600 data-item-center"
                colSpan={2}
              >
                Type
              </th>
              <th className="user-header-item fw-600 ">Description</th>
            </tr>
          </thead>
          <tbody>
            {services.map((item) => {
              const { _id: id, name, description, type } = item ?? {};

              return (
                <tr className="data-row-verticle-middle" key={id}>
                  <td className="user-data-item data-item-captilize data-item-center">
                    {name}
                  </td>
                  <td className="user-data-item  data-item-center" colSpan={2}>
                    <span className="acc-status-badge status-badge-active">
                      {type}
                    </span>
                  </td>
                  <td className="user-data-item data-item-captilize data-item-captilize text-nowrap">
                    {description}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>No matching result</div>
      )}
    </div>
  );
};

const Services = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(true);
  const [services, setServices] = useState([]);

  useEffect(() => {
    if (fetch) {
      (async function () {
        const result = await fetchServices(enqueueSnackbar);
        if (result.statusCode === 200) {
          setServices(result.data);
        }
      })();
    }
    return setFetch(false);
  }, [fetch, setServices, enqueueSnackbar]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // Handle form submission here
    const result = await createServices(values, enqueueSnackbar);

    if (result?.data?.statusCode === 201) {
      enqueueSnackbar("Service created Successfully", { variant: "success" });
      setFetch(true);
    }
    resetForm();
    setSubmitting(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().nullable(),
    type: Yup.string().required(" Type is required"),
  });

  return (
    <>
      <div
        className="col-lg-10 col-xl-8 col-xxl-6"
        style={{
          borderRadius: "8px",
          border: "1px solid #C8C8C8",
          padding: "15px",
          backgroundColor: "#fff",
        }}
      >
        <Formik
          initialValues={servicesInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <div className="row m-0 w-100 row-gap-3">
                <div className="col-12 col-md-8 grid-col-side-padding ">
                  <label
                    htmlFor="modifyLocationTitle"
                    className="form-label primary-form-label"
                  >
                    Name
                  </label>
                  <Field
                    type="text"
                    name="name"
                    className={`form-control primary-form-control ${
                      errors.name && touched.name ? "is-invalid" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="col-12 col-md-8 grid-col-side-padding ">
                  <label
                    htmlFor="modifyLocationCategory"
                    className="form-label primary-form-label"
                  >
                    Service Type
                  </label>
                  <Field
                    as="select"
                    name="type"
                    className={`form-select primary-focus primary-form-control ${
                      touched.type && errors.type ? "is-invalid" : ""
                    }`}
                  >
                    <option value="" disabled>
                      Service Type
                    </option>
                    {ENUM_SERVICE_OPTIONS.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="type"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="col-12 col-md-8 grid-col-side-padding ">
                  <label
                    htmlFor="modifyLocationLatitude"
                    className="form-label primary-form-label"
                  >
                    Description
                  </label>
                  <Field
                    component="textarea"
                    rows="3"
                    name="description"
                    className="form-control primary-form-control"
                  />
                </div>

                <div className="col-12 col-md-8 px-0 my-5">
                  <button
                    type="submit"
                    className="btn fribe-primary-btn modify-location-btn-submit w-100"
                  >
                    Submit
                  </button>
                  {/* <button className="btn fribe-primary-outline-btn">Update</button> */}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ServiceTableList services={services} />
    </>
  );
};

export default Services;
