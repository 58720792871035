import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { MdEdit, MdColorLens } from "react-icons/md";
import ImageWithFallback from "./components/ImageFallback";
import { fetchActiveCategory, updateCategoryColor } from "../../utils/apis";
import { useSnackbar } from "notistack";
import Switch from '@mui/material/Switch';
import { SketchPicker } from 'react-color';
import debounce from 'lodash.debounce';

const CategoriesListTable = ({
  categories = [],
  handleChangeStatus,
  onDelete = () => { },
  onEdit = () => { },
  onAdd = () => { },
  onSelectCategory = () => { },
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [color, setColor] = useState('#ffffff'); // Default color state
  const [selectedCategory, setSelectedCategory] = useState(null); // Track which category is selected
  const [showColorPicker, setShowColorPicker] = useState(null); // Track which category's color picker is visible
  const colorPickerRef = useRef(null);


  const handleSwitchChange = async (event, categoryId) => {
    const isActive = event.target.checked;
    await fetchActiveCategory({ categoryId, active: isActive }, enqueueSnackbar);
    handleChangeStatus(categoryId, isActive);
  };

  const handleColorChange = debounce(async (color, categoryId) => {
    setColor(color.hex);
    setSelectedCategory(categoryId);
    await updateCategoryColor(categoryId, color.hex, enqueueSnackbar);
    const index = categories.findIndex((item) => item.shortId === categoryId)
    categories[index].color = color.hex
  }, 300);

  const toggleColorPicker = (categoryId) => {
    setShowColorPicker(showColorPicker === categoryId ? null : categoryId);
  };
  const handleClickOutside = (event) => {
    if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
      setShowColorPicker(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <p className="fs-24 fw-400 text-black mb-0">Categories List</p>
        <button className="btn fribe-primary-btn" onClick={onAdd}>
          Add
        </button>
      </div>
      {categories.length ? (
        <table className="table">
          <thead>
            <tr>
              <th className="user-header-item">Name</th>
              <th className="user-header-item header-item-center">Status</th>
              <th className="user-header-item">Icon</th>
              <th className="user-header-item header-item-center">Color</th>
              <th className="user-header-item header-item-center">Created At</th>
              <th className="user-header-item header-item-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((item) => {
              const { shortId: id, name, createdAt, icon, color: initialColor } = item ?? {};
              return (
                <tr className="data-row-verticle-middle" key={id}>
                  <td className="user-data-item data-item-captilize">{name}</td>
                  <td className="user-data-item data-item-center">
                    <Switch
                      checked={item.status}
                      onChange={(e) => handleSwitchChange(e, id)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </td>
                  <td className="user-data-item">
                    <ImageWithFallback icon={icon} />
                  </td>
                  <td className="user-data-item data-item-center" style={{ position: 'relative' }}>
                    <button className="btn" onClick={() => toggleColorPicker(id)}>
                      <MdColorLens />
                    </button>
                    <span
                      style={{
                        // marginLeft: '5px',
                        padding: '5px 5px',
                        // backgroundColor: selectedCategory === id ? color : initialColor || '#ffffff',
                        color: 'black',
                        borderRadius: '5px',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        display: 'inline-block',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      {selectedCategory === id ? color : initialColor || '#ffffff'}
                    </span>
                    {showColorPicker === id && (
                      <div ref={colorPickerRef} style={{ position: 'absolute', zIndex: 2 }}>
                        <SketchPicker
                          color={selectedCategory === id ? color : initialColor || '#ffffff'}
                          onChange={(color) => handleColorChange(color, id)}
                        />
                      </div>
                    )}
                  </td>
                  <td className="user-data-item data-item-center">
                    {moment(createdAt).format("MMM D, YYYY [at] HH:mm A")}
                  </td>
                  <td className="user-data-item data-item-center">
                    <button
                      className="btn"
                      onClick={() => {
                        onEdit(id, item);
                      }}
                    >
                      <MdEdit />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>No matching result found</div>
      )}
    </div>
  );
};

export default CategoriesListTable;
