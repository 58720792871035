import server from "./server";
import mapServer from "./mapServer";
export async function createLocation(payload, enqueueSnackbar) {
  try {
    const result = await mapServer.post("/admin/place", payload);
    enqueueSnackbar(result.data.message, {
      variant: "success",
      autoHideDuration: 3000,
    });

    return result;
  } catch (error) {
    console.log("error: ", error);
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function uploadExcel(file, enqueueSnackbar) {
  try {
    const result = await mapServer.post("/admin/place/uploadexcel-data", file);
    console.log("result: ", result);

    return true;
  } catch (error) {
    console.log("error: ", error);
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function login(data, enqueueSnackbar) {
  try {
    const result = await server.post(`/public/user/login`, {
      email: data.email,
      password: data.password,
      role: "admin",
    });
    return result;
  } catch (error) {
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function fetchUserData(enqueueSnackbar) {
  try {
    const result = await server.get(
      `/admin/user/get-by-access-token/${localStorage.access_token}`
    );
    return result.data.data;
  } catch (error) {
    if (error?.response?.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error?.response?.data?.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function fetchUsersListsData(filters, enqueueSnackbar) {
  try {
    const result = await server.get(
      `/admin/user/list?search=${filters.search || ""}&perPage=${
        filters.perPage || 20
      }&page=${filters.page || 1}&orderBy=${
        filters.orderBy || "createdAt"
      }&orderDirection=${filters.orderDirection || "asc"}&type=${filters.type}`
    );
    return result.data.data;
  } catch (error) {
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function activateUser(userId, enqueueSnackbar) {
  try {
    const result = await server.patch(`/admin/user/update/${userId}/active`);
    return result.data.data;
  } catch (error) {
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function inactivateUser(userId, enqueueSnackbar) {
  try {
    const result = await server.patch(`/admin/user/update/${userId}/inactive`);
    return result.data.data;
  } catch (error) {
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function getGooglePlace(id, enqueueSnackbar) {
  try {
    const result = await mapServer.get(`/admin/place/${id}`);
    return result.data.data;
  } catch (error) {
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function searchPlacesByText(filters, enqueueSnackbar) {
  try {
    const result = await mapServer.get(
      `/admin/place/textsearch?search=${filters.search}`
    );
    return result.data;
  } catch (error) {
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function searchPlacesByCoordinates(filters, enqueueSnackbar) {
  try {
    const result = await mapServer.get(
      `/admin/place?lat=${filters.latitude}&long=${filters.longitude}`
    );
    return result.data;
  } catch (error) {
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function getPlacesByCoordinates(filters, enqueueSnackbar) {
  try {
    const result = await mapServer.get(
      `/admin/place/nearbysearch?name=${filters.name || ""}&radius=${
        filters.radius
      }&location=${filters.location}`
    );
    return result.data;
  } catch (error) {
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function createServices(data, enqueueSnackbar) {
  try {
    const result = await server.post(`/admin/services/create`, data);
    return result;
  } catch (error) {
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function fetchServices(enqueueSnackbar) {
  try {
    const result = await server.get(`/admin/services/list`);
    return result.data;
  } catch (error) {
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function fetchRoles(enqueueSnackbar) {
  try {
    const result = await server.get(`/admin/role/list`);
    return result.data;
  } catch (error) {
    console.log("error: ", error);
    console.log("error.response.statusCode: ", error.response.statusCode);
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function createSubAdmin(data, enqueueSnackbar) {
  try {
    const result = await server.post(`/admin/user/create`, data);
    return result;
  } catch (error) {
    console.log("error: ", error);
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function createPlan(data, enqueueSnackbar) {
  try {
    const result = await server.post("/admin/plans/create", data);
    enqueueSnackbar(`Plan created successfully.`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function updatePlan(data, enqueueSnackbar) {
  try {
    const result = await server.post("/admin/plans/update", data);
    enqueueSnackbar(`Plan Updated successfully.`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}
export async function createPayAsGoPlan(data, enqueueSnackbar) {
  try {
    const result = await server.post("/admin/plans/create/payAsGo", data);
    enqueueSnackbar(`Plan created successfully.`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function updatePayAsGoPlan(data, enqueueSnackbar) {
  try {
    const result = await server.post("/admin/plans/update/payAsGo", data);
    enqueueSnackbar(`Plan Updated successfully.`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function fetchPlansListsData(enqueueSnackbar) {
  try {
    const result = await server.get("/admin/plans/list");
    return result.data.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}
export async function fetchServicesListsData(enqueueSnackbar) {
  try {
    const result = await server.get("/admin/services/list");
    return result.data.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function deleteUser(id, enqueueSnackbar) {
  try {
    const result = await server.delete(`admin/user/delete/${id}`);
    return result;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function fetchAdminDashboardData(enqueueSnackbar) {
  try {
    const result = await server.get("/admin/dashboard");
    return result.data.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error?.response?.data?.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function updateUserPlan(data, enqueueSnackbar) {
  try {
    const result = await server.put("admin/user/update/plan", data);
    enqueueSnackbar(`Plan Updated successfully.`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function fetchBillingInfo(enqueueSnackbar) {
  try {
    const result = await server.get("user/billing/info");
    return result.data.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function fetchCountries(enqueueSnackbar) {
  try {
    const result = await server.get(`/admin/countries/list`);
    return result.data;
  } catch (error) {
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function addCountries(data, enqueueSnackbar) {
  try {
    const result = await server.post("admin/countries/create", data);
    enqueueSnackbar(`Country Added successfully.`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function deleteCountries(id, enqueueSnackbar) {
  try {
    const result = await server.delete("admin/countries/delete?id=" + id);
    enqueueSnackbar(`Country Deleted successfully.`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function fetchPolygons(enqueueSnackbar) {
  try {
    const result = await server.get(`/admin/polygon/list`);
    return result.data;
  } catch (error) {
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function addPolygon(data, enqueueSnackbar) {
  try {
    const result = await server.post("admin/polygon/create", data);
    enqueueSnackbar(`Polygon Added successfully.`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function updatePolygon(id, data, enqueueSnackbar) {
  try {
    const result = await server.put("admin/polygon/update?id=" + id, data);
    enqueueSnackbar(`Polygon Updated successfully.`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function deletePolygon(id, enqueueSnackbar) {
  try {
    const result = await server.delete("admin/polygon/delete?id=" + id);
    enqueueSnackbar(`Polygon Deleted successfully.`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function createTrialPlan(data, enqueueSnackbar) {
  try {
    const result = await server.post("/admin/plans/create/trial", data);
    enqueueSnackbar(`Plan created successfully.`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function updateTrialPlan(data, enqueueSnackbar) {
  try {
    const result = await server.put("/admin/plans/update/trial", data);
    enqueueSnackbar(`Plan updated successfully.`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function fetchCategories(enqueueSnackbar) {
  try {
    const result = await mapServer.get(`/admin/category`);
    return result.data;
  } catch (error) {
    if (error.response.statusCode === 401) {
      enqueueSnackbar(`Session expire Please login again`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function addCategory(data, enqueueSnackbar) {
  try {
    const result = await mapServer.post("admin/category", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    enqueueSnackbar(`Category added successfully.`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}
export async function fetchActiveCategory(data, enqueueSnackbar) {
  try {
    const result = await mapServer.patch(
      "admin/category/activeCategory",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    enqueueSnackbar(`Category Activity`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function updateCategory(id, data, enqueueSnackbar) {
  try {
    const result = await mapServer.patch("admin/category?shortId=" + id, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    enqueueSnackbar(`Category updated successfully.`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}

export async function updateCategoryColor(categoryId, color, enqueueSnackbar) {
  try {
    const result = await mapServer.patch(
      "admin/category/updateColor",
      { categoryId, color },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    enqueueSnackbar("Category color updated successfully.", {
      variant: "success",
      autoHideDuration: 3000,
    });

    return result.data;
  } catch (error) {
    console.error("Error updating category color:", error);
    error &&
      enqueueSnackbar(
        `${
          error.response?.data?.message || "Failed to update category color."
        }`,
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );

    return false;
  }
}

export async function exportLocations(enqueueSnackbar) {
  try {
    const response = await mapServer.get("/admin/place/export-csv", {
      responseType: "blob",
    });

    // Create a blob link to download the CSV
    const blob = new Blob([response.data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "locations.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();

    enqueueSnackbar("Locations exported successfully.", {
      variant: "success",
      autoHideDuration: 3000,
    });
  } catch (error) {
    console.error("Error exporting locations:", error);
    enqueueSnackbar(
      `${error.response?.data?.message || "Failed to export locations."}`,
      {
        variant: "error",
        autoHideDuration: 3000,
      }
    );
  }
}

export async function fetchPlaces(enqueueSnackbar, { page = 1, perPage = 10 ,country,location}) {
  try {

    console.log('Fetching places with parameters:', { page, perPage, country, location });

    const result = await mapServer.get(`/admin/placeManagement`, {
      params: { page, perPage, country,location},
    });
    return result.data;
  } catch (error) {
    if (error.response && error.response.statusCode === 401) {
      enqueueSnackbar(`Session expired. Please login again.`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    if (error.response) {
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(`An unexpected error occurred.`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    return false;
  }
}

export async function addPlace(formData, enqueueSnackbar) {
  try {
    const result = await mapServer.post(`/admin/placeManagement`, formData);

    if (result.status === 201) {
      enqueueSnackbar(`Place updated successfully.`, {
        variant: "success",
        autoHideDuration: 3000,
      });
      return result.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      enqueueSnackbar(`Session expired. Please login again.`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    if (error.response) {
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(`An unexpected error occurred.`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    return false;
  }
}

export async function updatePlace(id, formData, enqueueSnackbar) {
  try {
    const result = await mapServer.patch(
      `/admin/placeManagement?shortId=${id}`,
      formData
    );

    if (result.status === 200 || result.status === 204) {
      enqueueSnackbar(`Place updated successfully.`, {
        variant: "success",
        autoHideDuration: 3000,
      });
      return result.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      enqueueSnackbar(`Session expired. Please login again.`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    if (error.response) {
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(`An unexpected error occurred.`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    return false;
  }
}

export async function deletePlace(ids, enqueueSnackbar) {
  try {
    const result = await mapServer.delete("admin/placeManagement", {
      data: { ids: ids },
    });
    enqueueSnackbar(`Place deleted successfully.`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    return result.data;
  } catch (error) {
    error &&
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    return false;
  }
}
