import {
  DrawingManager,
  GoogleMap,
  Polygon,
  Rectangle,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMounted } from "../../../hooks/use-mounted";

const GeoMap = ({
  paths = [],
  center,
  formik,
  isEditMode,
  pivotPoint,
  isPivotEnabled = false,
}) => {
  const isMounted = useMounted();
  const polygonRef = useRef();
  const rectangleRef = useRef();
  const listenerRef = useRef([]);
  const [shapePath, setShapePath] = useState(paths);
  const [drawingMode, setDrawingMode] = useState(false);
  const [clickedPosition, setClickedPosition] = useState(null);
  const [formattedAddress, setFormattedAddress] = useState(null);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);

  const options = useMemo(() => {
    return {
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_RIGHT,
        drawingModes: ["rectangle", "polygon"],
      },
      drawingMode: "polygon",
      polygonOptions: {
        editable: true,
        zIndex: 1,
      },
    };
  }, []);

  const onPolygonUnmount = () => {
    listenerRef.current.forEach((listener) => listener?.remove());
    polygonRef.current = null;
  };

  const onRectangleUnmount = () => {
    rectangleRef.current = null;
  };

  // ******************** Pivot Point Side Effect ********************

  useEffect(() => {
    if (pivotPoint?.length > 0) {
      const geocoder = new window.google.maps.Geocoder();
      const latLng = {
        lat: pivotPoint[0],
        lng: pivotPoint[1],
      };

      // Perform reverse geocoding to obtain the formatted address
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            setFormattedAddress(results[0].formatted_address);
          }
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      });

      setClickedPosition(latLng);
    }
  }, [pivotPoint]);

  useEffect(() => {
    if (isMounted() && !isPivotEnabled) {
      setClickedPosition(null);
      setFormattedAddress(null);
      setInfoWindowOpen(null);
    }
  }, [isPivotEnabled, isMounted]);

  const renderShapes = useCallback(() => {
    const onPolygonLoad = (polygon) => {
      polygonRef.current = polygon;
    };

    const onRectangleLoad = (rectangle) => {
      rectangleRef.current = rectangle;
    };

    const onRectangleEditComplete = () => {
      if (rectangleRef.current) {
        const nextBounds = rectangleRef.current?.getBounds();
        const ne = nextBounds.getNorthEast(); // North-east corner
        const sw = nextBounds.getSouthWest(); // South-west corner
        const nextPath = [
          [
            [sw.lat(), sw.lng()], // Bottom-left corner [longitude, latitude]
            [ne.lat(), sw.lng()], // Bottom-right corner [longitude, latitude]
            [ne.lat(), ne.lng()], // Top-right corner [longitude, latitude]
            [sw.lat(), ne.lng()], // Top-left corner [longitude, latitude]
            [sw.lat(), sw.lng()], // Closing the polygon [longitude, latitude]
          ],
        ];
        // const nextPath = [[[ne.lat(), sw.lat(), ne.lng(), sw.lng()]]];
        formik.setFieldValue("coordinates", nextPath);
      }
    };

    const onPolygonEditComplete = () => {
      if (polygonRef.current) {
        let paths = [];
        const nextPath = polygonRef.current
          ?.getPath()
          .getArray()
          ?.map((latLng) => ({
            lat: latLng?.lat(),
            lng: latLng?.lng(),
          }));
        setShapePath(nextPath);
        nextPath.forEach((path) => {
          paths.push([path.lat, path.lng]);
        });
        formik.setFieldValue("coordinates", [paths]);
      }
    };

    const onPolygonComplete = (polygonPoints) => {
      const polyArray = polygonPoints.getPath().getArray();
      let paths = [];
      polyArray.forEach((path) => {
        paths.push([path.lat(), path.lng()]);
      });
      paths.push(paths[0]);
      formik.setFieldValue("coordinates", [paths]);
      formik.setFieldValue("type", "polygon");
      if (isPivotEnabled) {
        setDrawingMode(false);
      }
    };

    const onRectangleComplete = (rectangle) => {
      const bounds = rectangle.getBounds();
      const ne = bounds.getNorthEast(); // North-east corner
      const sw = bounds.getSouthWest(); // South-west corner
      const paths = [
        [
          [sw.lat(), sw.lng()], // Bottom-left corner [longitude, latitude]
          [ne.lat(), sw.lng()], // Bottom-right corner [longitude, latitude]
          [ne.lat(), ne.lng()], // Top-right corner [longitude, latitude]
          [sw.lat(), ne.lng()], // Top-left corner [longitude, latitude]
          [sw.lat(), sw.lng()], // Closing the polygon [longitude, latitude]
        ],
      ];
      formik.setFieldValue("coordinates", paths);
      formik.setFieldValue("type", "rectangle");
    };

    const handleMapClick = (e) => {
      const geocoder = new window.google.maps.Geocoder();
      const latLng = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };

      // Perform reverse geocoding to obtain the formatted address
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            setFormattedAddress(results[0].formatted_address);
            formik.setFieldValue(
              "formattedAddress",
              results[0].formatted_address
            );
          }
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      });

      setClickedPosition(latLng);
      if (isPivotEnabled) {
        formik.setFieldValue("pivotPoint", [latLng.lat, latLng.lng]);
      }
    };

    const handleMapDoubleClick = () => {
      if (!drawingMode && isPivotEnabled) {
        // Activate drawing manager on double click
        setDrawingMode(true);
      }
    };

    const showInfoWindow = () => {
      if (isPivotEnabled) {
        setInfoWindowOpen(true);
      }
    };

    if (shapePath?.length > 0 && formik.values?.type === "rectangle") {
      return (
        <GoogleMap
          mapContainerClassName="map-drawing-container"
          zoom={13}
          center={center}
        >
          <Rectangle
            bounds={shapePath[0]}
            onLoad={onRectangleLoad}
            onUnmount={onRectangleUnmount}
            ref={rectangleRef}
            onBoundsChanged={onRectangleEditComplete}
            editable={isEditMode}
            onClick={isPivotEnabled ? handleMapClick : undefined}
            onDblClick={isPivotEnabled ? handleMapDoubleClick : undefined}
          />
        </GoogleMap>
      );
    } else if (shapePath?.length > 0 && formik.values?.type === "polygon") {
      return (
        <GoogleMap
          mapContainerClassName="map-drawing-container"
          zoom={13}
          center={center}
          options={{ disableDoubleClickZoom: isPivotEnabled ? true : false }}
          onClick={isPivotEnabled ? handleMapClick : undefined}
          onDblClick={isPivotEnabled ? handleMapDoubleClick : undefined}
        >
          {isPivotEnabled && clickedPosition && (
            <Marker position={clickedPosition} onClick={showInfoWindow}>
              {infoWindowOpen && (
                <InfoWindow onCloseClick={() => setInfoWindowOpen(false)}>
                  <p>{formattedAddress}</p>
                </InfoWindow>
              )}
            </Marker>
          )}
          <Polygon
            onLoad={onPolygonLoad}
            onUnmount={onPolygonUnmount}
            ref={polygonRef}
            path={shapePath}
            onMouseUp={onPolygonEditComplete}
            editable={isEditMode}
          />
        </GoogleMap>
      );
    } else {
      return (
        <GoogleMap
          mapContainerClassName="map-drawing-container"
          zoom={13}
          center={center}
          onClick={isPivotEnabled ? handleMapClick : undefined}
          onDblClick={isPivotEnabled ? handleMapDoubleClick : undefined}
          options={{ disableDoubleClickZoom: isPivotEnabled ? true : false }}
        >
          {isPivotEnabled && clickedPosition && (
            <Marker position={clickedPosition} onClick={showInfoWindow}>
              {infoWindowOpen && (
                <InfoWindow onCloseClick={() => setInfoWindowOpen(false)}>
                  <p>{formattedAddress}</p>
                </InfoWindow>
              )}
            </Marker>
          )}

          {(!isPivotEnabled || drawingMode) && (
            <DrawingManager
              options={options}
              onPolygonComplete={onPolygonComplete}
              onRectangleComplete={onRectangleComplete}
            />
          )}
        </GoogleMap>
      );
    }
  }, [
    center,
    formik,
    isEditMode,
    options,
    shapePath,
    drawingMode,
    clickedPosition,
    formattedAddress,
    infoWindowOpen,
    isPivotEnabled,
  ]);

  return (
    <div
      style={{
        width: "900px",
        maxWidth: "100%",
      }}
    >
      {renderShapes()}
    </div>
  );
};

export default GeoMap;
