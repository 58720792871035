import Swal from "sweetalert2";

export const confirmAlert = async (payload = {}) => {
  const result = await Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#f04438",
    cancelButtonColor: "#0000008a",
    confirmButtonText: "Delete",
  });

  return result.isConfirmed ? true : false;
};

export const confirmAlertWithPayload = async (payload = {}) => {
  const { title, text } = payload ?? {};
  const result = await Swal.fire({
    title,
    text,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#0000008a",
    confirmButtonText: "Confirm",
  });

  return result.isConfirmed ? true : false;
};
